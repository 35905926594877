/* eslint-disable max-len */
// eslint-disable-next-line import/no-extraneous-dependencies
import { QRCodeSVG } from 'qrcode.react';
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  Button, Typography, Image as ImageComponent, Loading
} from 'tfc-components';

import que1 from 'assets/images/20cauque/que1.jpg';
import que10 from 'assets/images/20cauque/que10.jpg';
import que11 from 'assets/images/20cauque/que11.jpg';
import que12 from 'assets/images/20cauque/que12.jpg';
import que13 from 'assets/images/20cauque/que13.jpg';
import que14 from 'assets/images/20cauque/que14.jpg';
import que15 from 'assets/images/20cauque/que15.jpg';
import que16 from 'assets/images/20cauque/que16.jpg';
import que17 from 'assets/images/20cauque/que17.jpg';
import que18 from 'assets/images/20cauque/que18.jpg';
import que19 from 'assets/images/20cauque/que19.jpg';
import que2 from 'assets/images/20cauque/que2.jpg';
import que20 from 'assets/images/20cauque/que20.jpg';
import que3 from 'assets/images/20cauque/que3.jpg';
import que4 from 'assets/images/20cauque/que4.jpg';
import que5 from 'assets/images/20cauque/que5.jpg';
import que6 from 'assets/images/20cauque/que6.jpg';
import que7 from 'assets/images/20cauque/que7.jpg';
import que8 from 'assets/images/20cauque/que8.jpg';
import que9 from 'assets/images/20cauque/que9.jpg';
import lacque from 'assets/images/lac-que.gif';
import lacqueimg from 'assets/images/lac-que.png';
import logo from 'assets/images/logo.png';
import que from 'assets/images/que.png';
import Icon from 'components/atoms/Icon';
import CameraCapture from 'components/organisms/CameraCapture';
import CustomModal from 'components/organisms/Modal';
import PageLayout from 'components/organisms/PageLayout';
import {
  checkLuckyDrawService, luckyDrawService
} from 'services/booth';
import { clearLocalStorage, getLocalStorage } from 'services/common/storage';
import {
  ROUTES_PATH, LOCALSTORAGE_KEY, USER_ROLE, COLORS,
} from 'utils/constants';
import mapModifiers, { delay } from 'utils/functions';

type Notify = {
  message: string;
  open: boolean;
  isSuccess: boolean;
  hasButton?: boolean;
};
const initNotify: Notify = { message: '', open: false, isSuccess: false };
type GreenCardType = {
  code: string;
  img: string;
};
const imgArr: GreenCardType[] = [
  {
    code: '2UU4',
    img: que1
  },
  {
    code: 'FQ65',
    img: que2
  },
  {
    code: 'YUNQ',
    img: que3
  },
  {
    code: 'HVR8',
    img: que4
  },
  {
    code: 'HZFY',
    img: que5
  },
  {
    code: 'SK30',
    img: que6
  },
  {
    code: 'AM5Y',
    img: que7
  },
  {
    code: 'JXJL',
    img: que8
  },
  {
    code: 'RDPN',
    img: que9
  },
  {
    code: '2AKD',
    img: que10
  },
  {
    code: 'HORI',
    img: que11
  },
  {
    code: 'GTHM',
    img: que12
  },
  {
    code: '2NM5',
    img: que13
  },
  {
    code: '4F97',
    img: que14
  },
  {
    code: '6AQJ',
    img: que15
  },
  {
    code: '921P',
    img: que16
  },
  {
    code: 'CFAP',
    img: que17
  },
  {
    code: 'YIPI',
    img: que18
  },
  {
    code: '2JPY',
    img: que19
  },
  {
    code: '0C0F',
    img: que20
  },
];

const Booth: React.FC = () => {
  const navigate = useNavigate();
  // 1: qr the xam, 2: noi dung the xam, 3: qr lucky draw, 4: lucky draw
  const [step, setStep] = React.useState(1);
  const [cantDraw, setCantDraw] = React.useState(false);
  const [init, setInit] = React.useState(true);
  const [showLuckDraw, setShowLuckDraw] = React.useState({ open: false, success: false });
  const [phone, setPhone] = React.useState('');
  const [qrCodeMessage, setQrCodeMessage] = React.useState(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [drawing, setDrawing] = React.useState(false);
  const [showTag, setShowTag] = React.useState(false);
  const [notify, setNotify] = React.useState(initNotify);
  const [imgQue, setImgQue] = React.useState<GreenCardType>(imgArr[0]);
  const [loadingDraw, setLoadingDraw] = useState(false);

  const getGreenCardMutate = (code: string) => {
    const value = imgArr.find((img) => img.code === code);
    if (value) {
      setImgQue(value);
      setStep(2);
    }
  };
  const { mutate: checkLuckyDrawMutate, isLoading: checkLuckyDrawLoading } = useMutation('checkLuckyDraw', checkLuckyDrawService, {
    onSuccess: (data) => {
      if (data) {
        setStep(4);
      } else {
        setCantDraw(true);
      }
    }
  });
  const { mutate: luckyDrawMutate, data: luckyDrawData } = useMutation('luckyDraw', luckyDrawService, {
    onSuccess: (data) => {
      if (!data.gift) {
        setShowLuckDraw({ open: true, success: false });
      } else { setShowLuckDraw({ open: true, success: true }); }
    },
    onSettled: () => {
      setLoadingDraw(false);
    }
  });
  const draw = async () => {
    setLoadingDraw(true);
    await delay(5000);
    setShowTag(true);
    setDrawing(false);
    await delay(2000);
    luckyDrawMutate({
      phone,
      greenCardCode: imgQue.code,
    });
  };
  const logout = useCallback(() => {
    clearLocalStorage();
    navigate(ROUTES_PATH.BOOTHLOGIN);
  }, [navigate]);
  useEffect(() => {
    const role = getLocalStorage(LOCALSTORAGE_KEY.ROLE);
    if (role && role !== USER_ROLE.BOOTH) {
      logout();
    }
  }, [logout]);

  if (init) {
    return (
      <div className="p-login">
        <PageLayout topLogo>
          <div className="p-login_boothContent">
            <div className="p-login_boothGif" onClick={() => setInit(false)}>
              <ImageComponent
                extendClasses="p-login_boothGif_img"
                imgSrc={lacque}
                alt="lacque"
                ratio={1843 / 2489}
              />
            </div>
          </div>
        </PageLayout>
      </div>
    );
  }

  return (
    <div className="p-booth">
      <PageLayout>
        {(() => {
          switch (step) {
            case 2:
              return (
                <div className="p-booth_congratulation" style={{ backgroundImage: `url(${imgQue.img})` }}>
                  <div className="p-home_logoutIc p-booth_congratulation_back" onClick={() => setStep(1)}>
                    <Icon iconName="back" size="64" />
                  </div>
                  <div className="p-booth_congratulation_button">
                    <Button
                      extendClasses="p-home_popup_button"
                      primaryColor={COLORS.green}
                      hoveredPrimaryColor={COLORS.green}
                      variant="primary"
                      loadingIndicator={<Loading.CircleDashed color={COLORS.white} />}
                      onClick={() => setQrCodeMessage(true)}
                    >
                      <Typography.Text
                        textStyle="uppercase"
                        extendClasses="fs-16x24"
                      >
                        Nhận thông điệp
                      </Typography.Text>
                    </Button>
                    <Button
                      extendClasses="p-home_popup_button"
                      primaryColor={COLORS.tangerine}
                      hoveredPrimaryColor={COLORS.tangerine}
                      variant="primary"
                      onClick={() => setStep(3)}
                    >
                      <Typography.Text
                        textStyle="uppercase"
                        extendClasses="fs-16x24"
                      >
                        Đi tiếp
                      </Typography.Text>
                    </Button>
                  </div>
                  <canvas id="canvas" ref={canvasRef} style={{ visibility: 'hidden' }} />
                </div>
              );
            case 3:
              return (
                <>
                  <div className="p-booth_logo">
                    <ImageComponent
                      imgSrc={logo}
                      alt="Tet Chill"
                      ratio={175 / 110}
                    />
                  </div>
                  <div className="p-booth_head">
                    <div className="p-home_logoutIc p-booth_logout" onClick={() => setStep(2)}>
                      <Icon iconName="back" size="30" />
                    </div>
                    <div className="p-booth_qrText">
                      <Typography.Text
                        fontweight="600"
                        extendClasses="fs-24x32 color-tangerine"
                      >
                        Mời bạn quét mã định danh để thử vận may
                      </Typography.Text>
                    </div>
                  </div>
                  <div className="p-booth_camera">
                    <CameraCapture
                      loading={checkLuckyDrawLoading}
                      facingMode="user"
                      handleScanCode={(code) => {
                        checkLuckyDrawMutate(code);
                        setPhone(code);
                      }}
                    />
                    <Typography.Text
                      extendClasses="fs-24x32 color-greenRyb p-booth_description"
                    >
                      Hướng mã QR về phía camera
                    </Typography.Text>
                  </div>
                </>
              );
            case 4:
              return (
                <>
                  <div className="p-booth_logo">
                    <ImageComponent
                      imgSrc={logo}
                      alt="Tet Chill"
                      ratio={175 / 110}
                    />
                  </div>
                  <div className="p-booth_head">
                    <div className="p-home_logoutIc p-booth_logout" onClick={() => setStep(3)}>
                      <Icon iconName="back" size="30" />
                    </div>
                  </div>
                  <div className="p-home_lacque">
                    <img src={drawing ? lacque : lacqueimg} alt="" />
                    <div className={mapModifiers('p-home_lacque_que', showTag && 'show')}>
                      <img className="p-home_lacque" src={que} alt="" />
                    </div>
                  </div>
                  <Button
                    extendClasses="p-home_lacque_button"
                    primaryColor={COLORS.tangerine}
                    hoveredPrimaryColor={COLORS.tangerine}
                    loading={loadingDraw}
                    disabled={loadingDraw}
                    loadingIndicator={<Loading.CircleDashed color={COLORS.white} />}
                    variant="primary"
                    onClick={() => {
                      setDrawing(true);
                      draw();
                    }}
                  >
                    LẮC QUẺ NGAY
                  </Button>
                </>
              );
            default:
              return (
                <>
                  <div className="p-booth_logo">
                    <ImageComponent
                      imgSrc={logo}
                      alt="Tet Chill"
                      ratio={175 / 110}
                    />
                  </div>
                  <div className="p-booth_head">
                    {/* <div className="p-home_logoutIc p-booth_logout" onClick={logout}>
                      <Icon iconName="logout" size="30" />
                    </div> */}
                    <div className="p-booth_qrText">
                      <Typography.Text
                        fontweight="600"
                        extendClasses="fs-16x24 color-tangerine"
                      >
                        Mời bạn quét mã QR code trên thẻ xăm
                        để được xem quẻ
                      </Typography.Text>
                    </div>
                  </div>
                  <div className="p-booth_camera">
                    <CameraCapture
                      facingMode="user"
                      handleScanCode={getGreenCardMutate}
                    />
                  </div>
                  <Typography.Text
                    extendClasses="fs-24x32 color-greenRyb p-booth_description"
                  >
                    Hướng mã QR về phía camera
                  </Typography.Text>
                </>
              );
          }
        })()}
      </PageLayout>
      <CustomModal
        isOpen={cantDraw}
        handleClose={() => setCantDraw(false)}
      >
        <div className="p-home_popup">
          <Typography.Text extendClasses="p-home_popup_text">
            Bạn đã tham gia lắc quẻ hôm nay rồi, vui lòng quay lại vào ngày mai
          </Typography.Text>
          <Button
            extendClasses="p-home_popup_button"
            primaryColor={COLORS.tangerine}
            hoveredPrimaryColor={COLORS.tangerine}
            variant="primary"
            onClick={() => {
              setCantDraw(false);
              setStep(1);
              setInit(true);
            }}
          >
            Trở về
          </Button>
        </div>
      </CustomModal>
      <CustomModal
        isOpen={showLuckDraw.open}
        handleClose={() => setShowLuckDraw({ open: false, success: false })}
      >
        <div className="p-home_popup">
          <Typography.Text
            textStyle="center"
            extendClasses="fs-24x32 color-greenRyb p-booth_modalText"
            fontweight="500"
          >
            {showLuckDraw.success ? 'Chúc mừng Quý khách!' : (
              <p>
                Đen thôi, đỏ mai quay lại
                {' '}
                <b>TẾT CHILL</b>
                {' '}
                nha!
              </p>
            )}
          </Typography.Text>
          {showLuckDraw.success ? (
            <>
              <Typography.Text extendClasses="fs-16x24 color-greenRyb p-booth_modalText" textStyle="center">
                Quý khách đã lắc trúng phần quà
              </Typography.Text>
              <Typography.Text
                extendClasses="fs-24x32 color-greenRyb p-booth_modalText"
                textStyle="center"
              >
                {luckyDrawData?.gift?.name}
              </Typography.Text>
            </>
          ) : (
            <div style={{ textAlign: 'center', marginTop: 30 }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="154" height="152" viewBox="0 0 154 152" fill="none">
                <path d="M76.663 151.458C118.922 151.458 153.18 117.774 153.18 76.2231C153.18 34.6718 118.922 0.987793 76.663 0.987793C34.4038 0.987793 0.145996 34.6718 0.145996 76.2231C0.145996 117.774 34.4038 151.458 76.663 151.458Z" fill="url(#paint0_linear_275_11135)" />
                <path d="M46.9898 61.9031C50.9961 61.9031 54.2438 56.6698 54.2438 50.2143C54.2438 43.7587 50.9961 38.5254 46.9898 38.5254C42.9836 38.5254 39.7358 43.7587 39.7358 50.2143C39.7358 56.6698 42.9836 61.9031 46.9898 61.9031Z" fill="black" />
                <path d="M104.379 61.9031C108.385 61.9031 111.633 56.6698 111.633 50.2143C111.633 43.7587 108.385 38.5254 104.379 38.5254C100.373 38.5254 97.125 43.7587 97.125 50.2143C97.125 56.6698 100.373 61.9031 104.379 61.9031Z" fill="black" />
                <path d="M33.3166 76.6164C40.8679 76.6164 46.9896 74.1019 46.9896 71.0001C46.9896 67.8983 40.8679 65.3838 33.3166 65.3838C25.7652 65.3838 19.6436 67.8983 19.6436 71.0001C19.6436 74.1019 25.7652 76.6164 33.3166 76.6164Z" fill="#F48765" />
                <path d="M120.009 76.6164C127.56 76.6164 133.682 74.1019 133.682 71.0001C133.682 67.8983 127.56 65.3838 120.009 65.3838C112.458 65.3838 106.336 67.8983 106.336 71.0001C106.336 74.1019 112.458 76.6164 120.009 76.6164Z" fill="#F48765" />
                <path d="M95.523 101.037C94.803 101.036 94.1044 100.795 93.5425 100.352C92.9806 99.9093 92.5884 99.2919 92.4307 98.6011C92.3217 98.1508 89.5308 87.1689 76.4858 87.1689C63.5118 87.1689 61.0097 98.083 60.9087 98.5451C60.7412 99.3561 60.2529 100.068 59.5513 100.525C58.8496 100.982 57.9923 101.147 57.1675 100.982C56.3427 100.817 55.6181 100.337 55.1533 99.6474C54.6885 98.9574 54.5215 98.1143 54.689 97.3033C55.84 91.6407 61.8788 80.9282 76.4888 80.9282C91.0478 80.9282 97.335 91.5915 98.625 97.2305C98.7294 97.6874 98.7278 98.1615 98.6206 98.6178C98.5134 99.074 98.3031 99.5007 98.0054 99.8662C97.7077 100.232 97.3303 100.527 96.9009 100.729C96.4715 100.932 96.0012 101.037 95.5249 101.037H95.523Z" fill="#680C0C" />
                <defs>
                  <linearGradient id="paint0_linear_275_11135" x1="10.3993" y1="113.841" x2="141.798" y2="36.6831" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBB344" />
                    <stop offset="0.033" stopColor="#FBB643" />
                    <stop offset="0.178" stopColor="#FCC043" />
                    <stop offset="0.551" stopColor="#FCCC42" />
                    <stop offset="1" stopColor="#FDD642" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          )}
          <Button
            extendClasses="p-home_popup_button"
            primaryColor={COLORS.tangerine}
            hoveredPrimaryColor={COLORS.tangerine}
            variant="primary"
            onClick={() => {
              setInit(true);
              setStep(1);
              setShowLuckDraw({ open: false, success: false });
              setDrawing(false);
              setShowTag(false);
            }}
          >
            Trở về
          </Button>
        </div>
      </CustomModal>
      <CustomModal
        isOpen={qrCodeMessage}
        handleClose={() => setQrCodeMessage(false)}
      >
        <div className="p-home_popup">
          <div className="p-booth_qrText2024">
            <Typography.Text
              fontweight="600"
              extendClasses="fs-24x32 color-tangerine"
            >
              Quét mã QR để lưu giữ thông điệp năm 2024
            </Typography.Text>
          </div>
          <div className="p-booth_qr2024">
            <QRCodeSVG
              size={400}
              value={`${window.location.origin}/review-message?image=${imgQue.code}`}
            />
          </div>
          <Button
            extendClasses="p-home_popup_button"
            primaryColor={COLORS.tangerine}
            hoveredPrimaryColor={COLORS.tangerine}
            variant="primary"
            onClick={() => {
              setQrCodeMessage(false);
            }}
          >
            Đóng
          </Button>
        </div>
      </CustomModal>
      <CustomModal
        isOpen={notify.open}
      >
        <div className="p-home_popup">
          <Typography.Text textStyle="center" extendClasses="p-home_popup_title" fontweight="500">
            {notify.message}
          </Typography.Text>
          <Button
            extendClasses="p-home_popup_button"
            primaryColor={COLORS.tangerine}
            hoveredPrimaryColor={COLORS.tangerine}
            variant="primary"
            onClick={() => {
              setNotify(initNotify);
              setInit(true);
            }}
          >
            Đóng
          </Button>
        </div>
      </CustomModal>
    </div>
  );
};

export default Booth;
