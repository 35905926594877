import React, { useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Image,
  Typography
} from 'tfc-components';

import brands from 'assets/images/brands.png';
import Icon from 'components/atoms/Icon';
import CameraCapture from 'components/organisms/CameraCapture';
import CustomModal from 'components/organisms/Modal';
import PageLayout from 'components/organisms/PageLayout';
import LuckyWheel from 'components/templates/LuckyWheel';
import { clearLocalStorage, getLocalStorage } from 'services/common/storage';
import { pgCheckLuckyDrawService, pgLuckyDrawService } from 'services/pg';
import { Gift, LuckyDrawData } from 'services/pg/types';
import {
  COLORS,
  LOCALSTORAGE_KEY, ROUTES_PATH, USER_ROLE
} from 'utils/constants';

type Notify = {
  message: string;
  open: boolean;
  isSuccess: boolean;
  hasButton?: boolean;
};
const initNotify: Notify = { message: '', open: false, isSuccess: false };

const LuckyDraw: React.FC = () => {
  const navigate = useNavigate();
  const [qrcode, setQrcode] = React.useState(true);
  const [notify, setNotify] = React.useState(initNotify);
  const [phone, setPhone] = React.useState('');
  const [count, setCount] = React.useState(0);
  const [luckyData, setLuckyData] = React.useState<LuckyDrawData | null>(null);
  const [success, setSuccess] = React.useState(false);
  const [giftData, setGiftData] = React.useState<Gift[]>([]);
  const [loading, setLoading] = React.useState(false);

  const { mutate: checkGift, isLoading } = useMutation('checkGiftLuckyDraw', pgCheckLuckyDrawService, {
    onSuccess: (data) => {
      setQrcode(false);
      setCount(data.availableDrawTimes);
      const response = data.gifts;
      const index10k = response.findIndex((item) => item.code === 'HMW3kFvt');
      const indexbaolixi = response.findIndex((item) => item.code === 'vyBh5CQS');
      const slice1 = [...response.slice(0, index10k + 4),
      { ...response[index10k], code: 'HMW3kFvt-2' },
      ...response.slice(index10k + 4, response.length)];
      setGiftData(
        [...slice1.slice(0, indexbaolixi + 4),
        { ...slice1[indexbaolixi], code: 'vyBh5CQS-2' },
        ...slice1.slice(indexbaolixi + 4, slice1.length)]
      );
    },
    onError: () => {
      setNotify({ open: true, message: 'Đã có lỗi xảy ra. Vui lòng thử lại', isSuccess: true });
    }
  });
  const logout = useCallback(() => {
    clearLocalStorage();
    navigate(ROUTES_PATH.PGLOGIN);
  }, [navigate]);
  const onClick = async (phoneCode: string): Promise<LuckyDrawData | null> => {
    try {
      setLoading(true);
      const data = await pgLuckyDrawService(phoneCode);
      setCount(data.availableDrawTimes);
      setLuckyData(data);
      setTimeout(() => {
        setLoading(false);
      }, 5000);
      return data;
    } catch (error) {
      setNotify({ open: true, message: 'Đã có lỗi xảy ra. Vui lòng thử lại', isSuccess: true });
      setLoading(false);
      return null;
    }
  };

  useEffect(() => {
    const role = getLocalStorage(LOCALSTORAGE_KEY.ROLE);
    if (role && role !== USER_ROLE.PG) {
      logout();
    }
  }, [logout]);

  useEffect(() => {
    if (luckyData) {
      setTimeout(() => {
        setSuccess(true);
      }, 5000);
    }
  }, [luckyData]);

  return (
    <div className="p-pgScan p-luckyDraw">
      <PageLayout
        topLogo
      >
        <div className="p-pgScan_headerPg">
          <div className="p-pgScan_logoutPg" onClick={() => navigate(ROUTES_PATH.PG)}>
            <Icon iconName="back" size="36" />
          </div>
          <Typography.Text
            textStyle="center"
            extendClasses="color-tangerine fs-20x28"
          >
            {`${qrcode ? 'QUÉT MÃ QR' : 'Vòng quay may mắn'}`}
          </Typography.Text>
          <div />
        </div>
        <div className="p-luckyDraw_content">
          {qrcode ? (
            <div className="p-pgScan_camera">
              <CameraCapture
                handleScanCode={(code) => {
                  checkGift(code);
                  setPhone(code);
                }}
                loading={isLoading}
              />
            </div>
          ) : (
            <>
              <div className="u-mt-24">
                <Typography.Text textStyle="center" extendClasses="p-home_popup_title" fontweight="500">
                  {count === 0 ? 'Bạn đã hết lượt quay' : `Bạn còn ${count} lượt quay`}
                </Typography.Text>
              </div>
              <LuckyWheel
                allowDraw={count > 0}
                loading={loading}
                gifts={giftData}
                onClick={() => onClick(phone)}
              />
            </>
          )}
        </div>
        <div className="o-pageLayout_bottom">
          <div className="o-pageLayout_brands">
            <Image
              imgSrc={brands}
              ratio={297 / 103}
              alt="brands"
            />
          </div>
        </div>
      </PageLayout>
      <CustomModal
        isOpen={notify.open}
        handleClose={() => setNotify(initNotify)}
      >
        <div className="p-home_popup">
          <Typography.Text textStyle="center" extendClasses="p-home_popup_title" fontweight="500">
            {notify.message}
          </Typography.Text>
          <Button
            extendClasses="p-home_popup_button"
            primaryColor={COLORS.tangerine}
            hoveredPrimaryColor={COLORS.tangerine}
            variant="primary"
            onClick={() => setNotify(initNotify)}
          >
            Đóng
          </Button>
        </div>
      </CustomModal>
      <CustomModal
        isOpen={success}
      >
        <div className="p-home_popup">
          <Typography.Text textStyle="center" extendClasses="p-home_popup_title green" fontweight="500">
            Chúc mừng quý khách
          </Typography.Text>
          <div
            className="p-home_popup_imgWrap"
          >
            <Image extendClasses="p-home_popup_img" imgSrc={luckyData?.gift.image || ''} alt="" ratio={1} />
          </div>
          <Typography.Text textStyle="center" extendClasses="p-home_popup_title green" fontweight="500">
            {luckyData?.gift.name}
          </Typography.Text>
          <Button
            extendClasses="p-home_popup_button"
            primaryColor={COLORS.tangerine}
            hoveredPrimaryColor={COLORS.tangerine}
            variant="primary"
            onClick={() => {
              setSuccess(false);
              // if (luckyData && luckyData.availableDrawTimes > 0) {
              //   setSuccess(false);
              // } else {
              //   navigate(-1);
              // }
            }}
          >
            Trở về
          </Button>
        </div>
      </CustomModal>
    </div>
  );
};

export default LuckyDraw;
