import React from 'react';
import { Typography } from 'tfc-components';

interface FormFieldProps {
  label: string;
  required?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const FormField: React.FC<FormFieldProps> = ({
  label, required, children,
  className = ''
}) => (
  <div className={`o-formField ${className}`}>
    <Typography.Text
      extendClasses="o-formField_label color-richBlack"
      fontweight="400"
    >
      {label}
      {required && (
        <Typography.Text type="span" extendClasses="colors-red">
          {' '}
          *
        </Typography.Text>
      )}
    </Typography.Text>
    <div className="o-formField_input">
      {children}
    </div>
  </div>
);

FormField.defaultProps = {
  children: undefined,
};

export default FormField;
