import {
  CustomerJoinBoothParams, GiftForLuckyDrawData, LuckyDrawData, PGCheckPhoneData, PGGiftData
} from './types';

import axiosInstance from 'services/common/instance';

export const pgJoinBoothCustomerService = async (data: CustomerJoinBoothParams)
  : Promise<void> => {
  await axiosInstance.post('salespersons/customer-join-booths', data);
};

export const pgCheckPhoneService = async (data: CustomerJoinBoothParams)
  : Promise<PGCheckPhoneData> => {
  const response = await axiosInstance.post('salespersons/check-phone', data);
  return response.data.data;
};

export const pgCheckLuckyDrawService = async (phone: string): Promise<GiftForLuckyDrawData> => {
  const response = await axiosInstance.post('salespersons/gift-for-lucky-draw', { phone });
  return response.data.data;
};

export const pgLuckyDrawService = async (phone: string): Promise<LuckyDrawData> => {
  const response = await axiosInstance.post('salespersons/lucky-draw', { phone });
  return response.data.data;
};

export const pgGetGiftService = async (): Promise<PGGiftData[]> => {
  const reponse = await axiosInstance.get('salespersons/gifts');
  return reponse.data.data;
};
