import React from 'react';

import mapModifiers from 'utils/functions';

export const iconList = {
  logout: 'logout',
  lock: 'lock',
  unlock: 'unlock',
  quick: 'quick',
  back: 'back',
  camera: 'camera',
  edit: 'edit',
  warn: 'warn',
};

export type IconName = keyof typeof iconList;

export type IconSize = '16' | '18' | '24' | '28' | '30' | '32' | '36' | '64' | '80';
interface IconProps {
  iconName: IconName;
  size?: IconSize;
}

const Icon: React.FC<IconProps> = ({ iconName, size }) => (
  <i className={mapModifiers('a-icon', iconName, size)} />
);

Icon.defaultProps = {
  size: undefined,
};

export default Icon;
