import './App.scss';

import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import {
  BrowserRouter, Navigate, Route, Routes
} from 'react-router-dom';

import MainLayout from 'components/templates/MainLayout';
import useInAppDetect from 'hooks/useInAppDetect';
import Booth from 'pages/Booth';
import BoothLogin from 'pages/BoothLogin';
import Home from 'pages/Home';
import Login from 'pages/Login';
import LuckyDraw from 'pages/LuckyDraw';
import MessageImage from 'pages/MessageImage';
import Pg from 'pages/Pg';
import PgLogin from 'pages/PgLogin';
import PgScan from 'pages/PgScan';
import { getAccessToken } from 'services/common/storage';
import { store } from 'store';
import { ROUTES_PATH } from 'utils/constants';

const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const token = getAccessToken();
  const navigateRoute = (): string => {
    if (window.location.pathname.includes('/pg')) {
      return '/pg/login';
    } if (window.location.pathname.includes('/booth')) {
      return '/booth/login';
    }
    return '/login';
  };
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return token ? <>{children}</>
    : <Navigate to={navigateRoute()} />;
};

const App: React.FC = () => {
  const inApp = useInAppDetect();

  if (inApp) {
    return inApp === 'ios' ? (
      <span>
        Vui lòng nhấn và giữ
        {' '}
        <a href={window.location.href}>liên kết này</a>
        {' '}
        trong 02 giây để chuyển đổi trình duyệt phù hợp và trải nghiệm
      </span>
    ) : (
      <span>
        Vui lòng nhấn vào
        {' '}
        <a href={`intent://${window.location.host}${window.location.pathname}${window.location.search}#Intent;scheme=${window.location.protocol.replace(/\W/g, '')};action=android.intent.action.VIEW;end;`}>liên kết này</a>
        {' '}
        để chuyển đổi trình duyệt phù hợp và trải nghiệm.
      </span>
    );
  }
  return (
    <Suspense fallback>
      <Routes>
        <Route element={<MainLayout />}>
          <Route
            path={ROUTES_PATH.HOME}
            element={(
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            )}
          />
          <Route path={ROUTES_PATH.LOGIN} element={<Login />} />
          <Route path={ROUTES_PATH.PGLOGIN} element={<PgLogin />} />
          <Route
            path={ROUTES_PATH.PG}
            element={(
              <PrivateRoute>
                <Pg />
              </PrivateRoute>
            )}
          />
          <Route
            path={ROUTES_PATH.PGSCAN}
            element={(
              <PrivateRoute>
                <PgScan />
              </PrivateRoute>
            )}
          />
          <Route
            path={ROUTES_PATH.LUCKYDRAW}
            element={(
              <PrivateRoute>
                <LuckyDraw />
              </PrivateRoute>
            )}
          />
          <Route path={ROUTES_PATH.BOOTHLOGIN} element={<BoothLogin />} />
          <Route
            path={ROUTES_PATH.BOOTH}
            element={(
              <PrivateRoute>
                <Booth />
              </PrivateRoute>
            )}
          />
        </Route>
        <Route path={ROUTES_PATH.REVIEWMESSAGE} element={<MessageImage />} />
      </Routes>
    </Suspense>
  );
};

const AppProvider: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  });
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  );
};

export default AppProvider;
