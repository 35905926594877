import {
  CustomerProfile, ILogin, IPGLogin, SalesPersonProfile
} from './types';

import axiosInstance from 'services/common/instance';

// customer
export const getProfileCustomersService = async (): Promise<CustomerProfile> => {
  const response = await axiosInstance.get('customers/profile');
  return response.data.data;
};

export const customerLoginService = async (data: ILogin)
  : Promise<string> => {
  const response = await axiosInstance.post('customers/login', data);
  return response.data.data.accessToken;
};

// salesperson
export const getProfileSalesPersonService = async (): Promise<SalesPersonProfile> => {
  const response = await axiosInstance.get('salespersons/profile');
  return response.data.data;
};

export const salesPersonLoginService = async (data: IPGLogin)
  : Promise<string> => {
  const response = await axiosInstance.post('salespersons/login', data);
  return response.data.data.accessToken;
};

// booth
export const getProfileBoothService = async (): Promise<SalesPersonProfile> => {
  const response = await axiosInstance.get('ipads/profile');
  return response.data.data;
};

export const boothLoginService = async (data: IPGLogin)
  : Promise<string> => {
  const response = await axiosInstance.post('ipads/login', data);
  return response.data.data.accessToken;
};
