/* eslint-disable import/no-extraneous-dependencies */
import JsQR from 'jsqr';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Button, Loading, Typography } from 'tfc-components';

import CustomModal from '../Modal';

import { COLORS } from 'utils/constants';

export const SvgScan: React.FC = () => (
  <div className="t-camera_svgScan">
    <svg viewBox="0 0 100 125">
      <mask id="mask">
        <rect fill="white" width="100%" height="100%" />
        <rect fill="black" width="88" height="88" x="6" y="18" />
      </mask>
      <path d="M 6 18 H 94 V 106 H 6 Z" fill="transparent" stroke="#b84144" />
      <rect mask="url(#mask)" fill="black" opacity={0.5} width="100%" height="100%" />
    </svg>
  </div>
);

interface CameraCaptureProps {
  name?: string;
  facingMode?: 'user' | 'environment';
  loading?: boolean;
  handleScanCode?: (code: string) => void;
}

const CameraCapture: React.FC<CameraCaptureProps> = ({
  name,
  loading,
  facingMode,
  handleScanCode,
}) => {
  //* Hooks

  //* States
  const [preview, setPreview] = useState('');
  const [errRequest, setErrRequest] = useState(false);
  const [isSupport, setIsSupport] = useState(false);

  //* Refs
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const hasCode = useRef(false);

  //* Camera options
  const captureOption = useMemo(() => ({
    audio: false,
    video: {
      facingMode: facingMode ?? 'environment',
      width: { ideal: 480 },
      height: { ideal: 480 },
      frameRate: { max: 30 },
      aspectRatio: 1,
    },
  }), [facingMode]);

  //* Functions
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const stopSelfie = async () => {
    await navigator.mediaDevices.getUserMedia({ audio: false, video: true })
      .then((sm) => {
        if (!videoRef.current) return;
        videoRef.current.srcObject = sm;
        sm.getTracks().forEach((track) => {
          track.stop();
        });
      });
  };

  const tick = useCallback(() => {
    if (canvasRef?.current && videoRef?.current) {
      // const video = document.getElementById("camera");
      const video = videoRef.current;
      const canvasEl = canvasRef.current;
      const ctx = canvasEl.getContext('2d');
      if (ctx && video) {
        ctx.drawImage(video, 0, 0, canvasEl.width, canvasEl.height);
        const imageData = ctx.getImageData(0, 0, canvasEl.width, canvasEl.height);
        if (imageData) {
          const code = JsQR(imageData.data, imageData.width, imageData.height, {
            inversionAttempts: 'dontInvert',
          });
          if (code && !hasCode.current) {
            const imageDataURL = canvasRef.current.toDataURL('image/jpeg');
            setPreview(imageDataURL);
            handleScanCode?.(code.data);
            hasCode.current = true;
          }
        }
      }
    }
    requestAnimationFrame(tick);
  }, [handleScanCode]);
  useEffect(() => {
    requestAnimationFrame(tick);
  }, [tick]);

  //* Effects

  useEffect(() => {
    if (!errRequest) {
      const streamPromise = navigator.mediaDevices.getUserMedia(
        captureOption,
      ).catch((err: any) => {
        if (err.name === 'NotAllowedError') {
          setErrRequest(true);
        } else {
          setIsSupport(true);
        }
        return null;
      });
      if (videoRef) {
        streamPromise.then((stream) => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          }
        });
      }
      return () => {
        streamPromise.then((stream) => stream?.getTracks().forEach((track) => {
          track.stop();
        }));
      };
    }
    return () => { };
  }, [errRequest, captureOption]);

  return (
    <div className="t-camera">
      <div className="t-camera_wrapper">
        <div className="t-camera_camera">
          <video
            id={`video-${name}`}
            ref={videoRef}
            className="t-camera_video"
            autoPlay
            playsInline
            controls={false}
            muted
          />
          <canvas
            id={`canvas-${name}`}
            ref={canvasRef}
          />
          {preview && (
            <div className="t-camera_preview" style={{ backgroundImage: `url(${preview})` }} />
          )}
          {loading && (
            <div className="t-camera_loading">
              <Loading.CircleDashed color={COLORS.red} width={48} />
            </div>
          )}
        </div>
      </div>
      <CustomModal
        isOpen={errRequest}
        handleClose={() => setErrRequest(false)}
      >
        <div className="p-home_popup">
          <Typography.Text textStyle="center" extendClasses="p-home_popup_title" fontweight="500">
            Thất bại
          </Typography.Text>
          <Typography.Text extendClasses="p-home_popup_text">
            Chức năng này cần cho phép
            truy cập camera.
            <br />
            Hướng dẫn bật truy cập camera:\n
            iOS: Vào cài đặt
            {' '}
            {'>'}
            {' '}
            Safari
            {' '}
            {'>'}
            {' '}
            Camera
            {' '}
            {'>'}
            {' '}
            Chọn Cho phép
            Android:
          </Typography.Text>
          <Button
            extendClasses="p-home_popup_button"
            primaryColor={COLORS.tangerine}
            hoveredPrimaryColor={COLORS.tangerine}
            variant="primary"
            onClick={() => setErrRequest(false)}
          >
            OK
          </Button>
        </div>
      </CustomModal>
      <CustomModal
        isOpen={isSupport}
        handleClose={() => setIsSupport(false)}
      >
        <div className="p-home_popup">
          <Typography.Text textStyle="center" extendClasses="p-home_popup_title" fontweight="500">
            Thất bại
          </Typography.Text>
          <Typography.Text extendClasses="p-home_popup_text">
            Trình duyệt hoặc thiết bị của bạn không hỗ trợ
            camera để thực hiện tính năng này. Vui lòng kiểm tra lại.
          </Typography.Text>
          <Button
            extendClasses="p-home_popup_button"
            primaryColor={COLORS.tangerine}
            hoveredPrimaryColor={COLORS.tangerine}
            variant="primary"
            onClick={() => setIsSupport(false)}
          >
            OK
          </Button>
        </div>
      </CustomModal>
    </div>
  );
};

export default CameraCapture;
