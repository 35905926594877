import React, { InputHTMLAttributes } from 'react';

import mapModifiers from 'utils/functions';

type Variant = 'secondary';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  variant?: Variant;
  children?: React.ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label, id, variant, children, ...props
}) => (
  <div className={mapModifiers('a-checkbox', variant, props.checked && 'secondary-active')}>
    <label htmlFor={id} className="a-checkbox_wrapper">
      <input
        id={id}
        type="checkbox"
        {...props}
      />

      {children}
    </label>
  </div>
);

export default Checkbox;
