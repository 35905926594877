import React from 'react';
import { Container } from 'react-bootstrap';
import { Loading, Image } from 'tfc-components';

import brands from 'assets/images/brands.png';
import logo from 'assets/images/logo.png';
import useWindowDimensions from 'hooks/useWindowDemensions';
import { COLORS } from 'utils/constants';
import mapModifiers from 'utils/functions';

interface PageLayoutProps {
  loading?: boolean;
  light?: boolean;
  topLogo?: boolean;
  bottomLogo?: boolean;
  smallLogo?: boolean;
  children?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  loading, light, footer, header, children, topLogo, bottomLogo, smallLogo
}) => {
  const { height } = useWindowDimensions();

  return (
    <div className={mapModifiers('o-pageLayout', light && 'light')} style={{ height: `${height}px` }}>
      {header && (<div className="o-pageLayout_header">{header}</div>)}
      {topLogo && (
        <div
          className="o-pageLayout_logo"
          style={{ maxWidth: smallLogo ? '111px' : '' }}
        >
          <Image
            imgSrc={logo}
            ratio={175 / 110}
            alt="logo"
          />
        </div>
      )}
      <div className="o-pageLayout_content">
        <div className="o-pageLayout_children">
          {children}
        </div>
        {bottomLogo && (
          <div className="o-pageLayout_bottom">
            <div className="o-pageLayout_brands">
              <Image
                imgSrc={brands}
                ratio={297 / 103}
                alt="brands"
              />
            </div>
          </div>
        )}
      </div>
      {footer && (
        <footer className="o-pageLayout_footer">
          <div className="o-pageLayout_footer_content">
            <Container>
              {footer}
            </Container>
          </div>
        </footer>
      )}
      {loading && (
        <div className="o-pageLayout_loading">
          <Loading.CircleDashed extendClasses="o-pageLayout_loading_indicator" color={COLORS.oxfordBlue} width={48} />
        </div>
      )}
    </div>
  );
};

export default PageLayout;
