import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getProfileBoothService, getProfileCustomersService, getProfileSalesPersonService } from 'services/authenticate';
import { CustomerProfile, SalesPersonProfile } from 'services/authenticate/types';

interface AuthState {
  customer?: CustomerProfile;
  pg?: SalesPersonProfile;
  booth?: SalesPersonProfile
}

const initialState: AuthState = {
};

export const getCustomerProfileAsync = createAsyncThunk<CustomerProfile>(
  'authReducer/getCustomerProfile',
  async (_, { rejectWithValue }) => {
    try {
      return await getProfileCustomersService();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getPGProfileAsync = createAsyncThunk<SalesPersonProfile>(
  'authReducer/getPGProfileAsync',
  async (_, { rejectWithValue }) => {
    try {
      return await getProfileSalesPersonService();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getBoothProfileAsync = createAsyncThunk<SalesPersonProfile>(
  'authReducer/getBoothProfileAsync',
  async (_, { rejectWithValue }) => {
    try {
      return await getProfileBoothService();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const authSlice = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder.addCase(getCustomerProfileAsync.fulfilled, ($state, action) => {
      $state.customer = action.payload;
    });
    builder.addCase(getPGProfileAsync.fulfilled, ($state, action) => {
      $state.pg = action.payload;
    });
    builder.addCase(getBoothProfileAsync.fulfilled, ($state, action) => {
      $state.booth = action.payload;
    });
  },
});

export default authSlice.reducer;
