/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { yupResolver } from '@hookform/resolvers/yup';
import { FineUploaderBasic } from 'fine-uploader/lib/core';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Controller, FormProvider, useForm, useWatch
} from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button, Input, Loading, Typography
} from 'tfc-components';
import * as yup from 'yup';

import Icon from 'components/atoms/Icon';
import CameraCapture from 'components/organisms/CameraCapture';
import FormField from 'components/organisms/FormField';
import CustomModal from 'components/organisms/Modal';
import PageLayout from 'components/organisms/PageLayout';
import { clearLocalStorage, getLocalStorage } from 'services/common/storage';
import { pgCheckPhoneService, pgJoinBoothCustomerService } from 'services/pg';
import { ChunkingImage, CustomerJoinBoothParams, PGCheckPhoneReason } from 'services/pg/types';
import { useAppSelector } from 'store/hooks';
import {
  COLORS, LOCALSTORAGE_KEY, ROUTES_PATH, URL_CONST, USER_ROLE
} from 'utils/constants';
import mapModifiers from 'utils/functions';

interface IUseBill {
  code: string;
  image: string;
}
const loginSchema: yup.ObjectSchema<IUseBill> = yup.object().shape({
  code: yup.string().required('Vui lòng cung cấp thông tin').matches(/^\w{8}$/, 'Vui lòng nhập mã hoá đơn đủ 8 ký tự'),
  image: yup.string().required('Vui lòng cung cấp thông tin'),
});

type Notify = {
  message: string;
  open: boolean;
  isSuccess: boolean;
  hasButton?: boolean;
};

const initNotify: Notify = { message: '', open: false, isSuccess: false };
const renderMessageError = (message: PGCheckPhoneReason) => {
  switch (message) {
    case 'customerNotEnoughGame':
      return 'Khách hàng cần chơi đủ các game để tham gia';
    case 'customerPlayedGame':
      return 'Khách hàng đã tham gia hái lộc';
    case 'invoiceIdUsed':
      return 'Hóa đơn đã được sử dụng';
    case 'boothInactive':
      return 'Khu vực không hoạt động';
    default:
      return '';
  }
};
const PgScan: React.FC = () => {
  const navigate = useNavigate();
  const pgProfile = useAppSelector((state) => state.authenticate.pg);
  const [searchParams] = useSearchParams();
  const isUseBill = searchParams.get('useBill');
  const [useBill, setUseBill] = React.useState(false);
  const [customerPhone, setCustomerPhone] = React.useState('');
  const [notify, setNotify] = React.useState(initNotify);
  const [uuidImages, setUuidImages] = React.useState<ChunkingImage>();
  const [loading, setLoading] = React.useState(false);
  const method = useForm<IUseBill>({
    mode: 'onChange',
    resolver: yupResolver(loginSchema),
    defaultValues: {
      code: '',
      image: '',
    },
  });
  const invoiceImages = useWatch({ control: method.control, name: 'image' });
  const uploader = useMemo(() => new FineUploaderBasic({
    autoUpload: false,
    request: {
      endpoint: URL_CONST.MEDIA_FILE_UPLOAD_CHUNK,
    },
    chunking: {
      enabled: true,
      mandatory: true,
      partSize: 1000000, // 1MB,
    },
    callbacks: {
      onTotalProgress(totalUploadedBytes: number, totalBytes: number) {
        if (totalUploadedBytes === totalBytes) {
          setLoading(false);
        }
      },
      onComplete(id: number) {
        const uuid = uploader.getUuid(id);
        const file = uploader.getFile(id);
        setUuidImages({ chunkedUuid: uuid, fileExtension: file.name.split('.').pop() });
      },
    }
  }), []);
  const { mutate: joinBooth, isLoading } = useMutation('joinBooth', pgJoinBoothCustomerService, {
    onSuccess: () => {
      setNotify({ open: true, message: 'Quét mã QR thành công', isSuccess: true });
    },
    onError: (errors: any) => {
      if (errors.length > 0) {
        errors.forEach((err: ValidateError) => {
          if (err.code === 'customerNotEnoughGame') {
            setNotify({ open: true, message: renderMessageError('customerNotEnoughGame'), isSuccess: true });
          } else if (err.code === 'customerPlayedGame') {
            setNotify({
              open: true, message: renderMessageError('customerPlayedGame'), isSuccess: true, hasButton: true
            });
          } else if (err.code === 'boothInactive') {
            setNotify({
              open: true, message: renderMessageError('boothInactive'), isSuccess: true, hasButton: true
            });
          }
        });
      } else {
        setNotify({ open: true, message: 'Đã có lỗi xảy ra. Vui lòng thử lại', isSuccess: true });
      }
    }
  });

  const { mutate: checkBill, isLoading: checkBillLoading } = useMutation('checkBill', (data: { params: CustomerJoinBoothParams, isBill?: boolean }) => pgCheckPhoneService(data.params), {
    onSuccess: (data, variables) => {
      if (variables?.isBill) {
        if (data.canPlayMore) {
          joinBooth(variables.params);
        } else {
          setNotify({ open: true, message: renderMessageError(data.reason), isSuccess: data.reason !== 'invoiceIdUsed' });
        }
      } else if (data.canPlayMore) {
        joinBooth(variables.params);
      } else {
        setNotify({ open: true, message: renderMessageError(data.reason), isSuccess: data.reason !== 'invoiceIdUsed' });
      }
    },
    onError: () => {
      setNotify({ open: true, message: 'Đã có lỗi xảy ra. Vui lòng thử lại', isSuccess: true });
    }
  });
  const onSubmit = (data: IUseBill) => {
    checkBill({
      params: {
        phone: customerPhone,
        invoiceId: data.code,
        invoiceImage: uuidImages
      },
      isBill: true
    });
  };
  const forceJoinBooth = (phone: string) => {
    joinBooth({ phone });
  };

  const logout = useCallback(() => {
    clearLocalStorage();
    navigate(ROUTES_PATH.PGLOGIN);
  }, [navigate]);

  useEffect(() => {
    const role = getLocalStorage(LOCALSTORAGE_KEY.ROLE);
    if (role && role !== USER_ROLE.PG) {
      logout();
    }
  }, [logout]);
  return (
    <div className="p-pgScan">
      <PageLayout
        topLogo
        bottomLogo
      >
        <div className="p-pgScan_headerPg">
          <div className="p-pgScan_logoutPg" onClick={() => navigate(-1)}>
            <Icon iconName="back" size="36" />
          </div>
          <Typography.Text
            textStyle="center"
            extendClasses="color-tangerine fs-20x28"
          >
            {`${useBill ? 'NHẬP THÔNG TIN HÓA ĐƠN' : 'QUÉT MÃ QR'}`}
          </Typography.Text>
          <div />
        </div>
        {useBill ? (
          <div className="p-pgScan_bill">
            <FormProvider {...method}>
              <FormField label="Mã hóa đơn">
                <Controller
                  name="code"
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Input
                        {...field}
                        bordered
                        type="text"
                        placeholder="Nhập mã hoá đơn"
                        extendClasses={mapModifiers('p-login_field', error && 'error')}
                      />
                      {error && (
                        <div className="form-error-message">{error.message}</div>
                      )}
                    </>
                  )}
                />
              </FormField>
              <FormField label="Vui lòng chụp hình ảnh hóa đơn rõ nét." className="u-mt-44">
                <label
                  htmlFor="file-upload"
                  className="p-pgScan_invoiceImages_capturePad"
                >
                  <div className="p-pgScan_invoiceImages_capturePadContent">
                    {invoiceImages && (
                      <img src={invoiceImages} alt="" />
                    )}
                    <Icon iconName={invoiceImages ? 'edit' : 'camera'} size={invoiceImages ? '32' : '80'} />
                    <Controller
                      name="image"
                      control={method.control}
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <>
                          <Input
                            id="file-upload"
                            name="invoiceImages"
                            extendClasses="p-pgScan_invoiceImages_upload"
                            accept="image/*"
                            type="file"
                            capture="environment"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              const file = event.target.files?.[0];
                              if (file?.type !== 'image/jpeg' && file?.type !== 'image/png' && file?.type !== 'image/jpg') {
                                method.setError('image', { message: 'Ảnh không đúng định dạng. <br /> Vui lòng chọn ảnh với định dạng .jpeg, .png, .jpg' });
                                return;
                              }
                              if (file) {
                                onChange(URL.createObjectURL(file));
                                setLoading(true);
                                uploader.addFiles(file);
                                uploader.uploadStoredFiles();
                              }
                            }}
                          />
                          {error && (
                            <div style={{ textAlign: 'center' }} className="text-error" dangerouslySetInnerHTML={{ __html: error.message || '' }} />
                          )}
                        </>
                      )}
                    />
                  </div>
                </label>
              </FormField>
              <Button
                type="submit"
                primaryColor={COLORS.tangerine}
                hoveredPrimaryColor={COLORS.tangerine}
                variant="primary"
                extendClasses="p-login_submit"
                onClick={method.handleSubmit(onSubmit)}
                loading={loading || checkBillLoading}
                loadingIndicator={<Loading.CircleDashed color={COLORS.white} />}
                disabled={loading || checkBillLoading}
              >
                <Typography.Text fontweight="500">Kiểm tra hóa đơn hợp lệ</Typography.Text>
              </Button>
            </FormProvider>
          </div>
        ) : (
          <div className="p-pgScan_camera">
            <CameraCapture
              handleScanCode={(code) => {
                if (pgProfile?.booth.isLastGame) {
                  if (isUseBill) {
                    setCustomerPhone(code);
                    setUseBill(true);
                  } else {
                    checkBill({ params: { phone: code } });
                  }
                } else {
                  forceJoinBooth(code);
                }
              }}
              loading={isLoading || checkBillLoading}
            />
          </div>
        )}
      </PageLayout>
      <CustomModal
        isOpen={notify.open}
        handleClose={() => setNotify(initNotify)}
      >
        <div className="p-home_popup">
          <Typography.Text textStyle="center" extendClasses="p-home_popup_title" fontweight="500">
            {notify.message}
          </Typography.Text>
          <Button
            extendClasses="p-home_popup_button"
            primaryColor={COLORS.tangerine}
            hoveredPrimaryColor={COLORS.tangerine}
            variant="primary"
            onClick={() => {
              if (notify.isSuccess) {
                navigate(ROUTES_PATH.PG);
              } else {
                setNotify(initNotify);
              }
            }}
          >
            Trở về
          </Button>
          {notify.hasButton && pgProfile?.booth.isLastGame && (
            <Button
              extendClasses="p-home_popup_button"
              primaryColor={COLORS.tangerine}
              hoveredPrimaryColor={COLORS.tangerine}
              variant="primary"
              onClick={() => setUseBill(true)}
            >
              Nhập thông tin hóa đơn
            </Button>
          )}
        </div>
      </CustomModal>
    </div>
  );
};

export default PgScan;
