/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { QRCodeSVG } from 'qrcode.react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Typography, Image } from 'tfc-components';

import JourneyModal from './journeyModal';

import buachill from 'assets/images/buachill.png';
import buachillcam from 'assets/images/buachillcam.png';
import buachillxam from 'assets/images/buachillxam.png';
import buachillxanh from 'assets/images/buachillxanh.png';
import hailoc from 'assets/images/hailoc.png';
import hailoccam from 'assets/images/hailoccam.png';
import hailocxam from 'assets/images/hailocxam.png';
import hailocxanh from 'assets/images/hailocxanh.png';
import journey from 'assets/images/journey.png';
import khutncam from 'assets/images/khutncam.png';
import khutnxam from 'assets/images/khutnxam.png';
import khutnxanh from 'assets/images/khutnxanh.png';
import khutrainghiem from 'assets/images/khutrainghiem.png';
import tarot from 'assets/images/tarot.png';
import tarotcam from 'assets/images/tarotcam.png';
import tarotxam from 'assets/images/tarotxam.png';
import tarotxanh from 'assets/images/tarotxanh.png';
import tetchill from 'assets/images/tetchill.png';
import thaydocam from 'assets/images/thaydocam.png';
import thaydoxam from 'assets/images/thaydoxam.png';
import thaydoxanh from 'assets/images/thaydoxanh.png';
import Icon from 'components/atoms/Icon';
import CustomModal from 'components/organisms/Modal';
import PageLayout from 'components/organisms/PageLayout';
import { clearLocalStorage, getLocalStorage } from 'services/common/storage';
import { getBoothDataService } from 'services/customer';
import { useAppSelector } from 'store/hooks';
import {
  COLORS, LOCALSTORAGE_KEY, ROUTES_PATH, USER_ROLE
} from 'utils/constants';
import mapModifiers from 'utils/functions';

const Home: React.FC = () => {
  const customer = useAppSelector((state) => state.authenticate.customer);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isQr = searchParams.get('qr');
  const [qrCode, setQrCode] = React.useState(!!isQr);
  const [isOpen, setIsOpen] = React.useState(false);
  const [index, setIndex] = React.useState<number | undefined>(undefined);
  const { data: boothData } = useQuery(['boothData'], getBoothDataService);
  const logout = useCallback(() => {
    clearLocalStorage();
    navigate(ROUTES_PATH.LOGIN);
  }, [navigate]);
  useEffect(() => {
    const role = getLocalStorage(LOCALSTORAGE_KEY.ROLE);
    if (role && role !== USER_ROLE.CUSTOMER) {
      logout();
    }
  }, [logout]);

  useEffect(() => {
    if (!qrCode) {
      queryClient.invalidateQueries('boothData');
    }
  }, [qrCode, queryClient]);

  const formattedBoothData = useMemo(() => boothData?.map((item) => ({
    name: item?.boothData?.name || '',
    content: item?.boothData?.introduction || '',
  })) || [], [boothData]);

  const journeyImgs = [
    {
      joined: khutnxanh,
      unjoined: khutncam,
      inactive: khutnxam,
      ratio: 273 / 33,
      maxWidth: 273,
    },
    {
      joined: thaydoxanh,
      unjoined: thaydocam,
      inactive: thaydoxam,
      ratio: 205 / 33,
      maxWidth: 205,
    },
    {
      joined: tarotxanh,
      unjoined: tarotcam,
      inactive: tarotxam,
      ratio: 160 / 33,
      maxWidth: 160,
    },
    {
      joined: buachillxanh,
      unjoined: buachillcam,
      inactive: buachillxam,
      ratio: 200 / 33,
      maxWidth: 200
    },
    {
      joined: hailocxanh,
      unjoined: hailoccam,
      inactive: hailocxam,
      ratio: 133 / 33,
      maxWidth: 133
    },
  ];

  return (
    <div className="p-home">
      <PageLayout
        topLogo
        smallLogo={!qrCode}
        footer={(
          <div className="p-home_journeyFooter">
            <div className="p-home_journeyFooter_qr" onClick={() => setQrCode(true)}>
              <Typography.Text
                fontweight="500"
                extendClasses="color-white"
              >
                Mã QR của tôi
              </Typography.Text>
            </div>
            <div className="p-home_journeyFooter_tour" onClick={() => setQrCode(false)}>
              <Typography.Text
                extendClasses="color-white"
                fontweight="500"
              >
                Hành trình
                <br />
                trải nghiệm
              </Typography.Text>
            </div>
          </div>
        )}
      >
        <div className="p-home_content">
          {qrCode ? (
            <div className="p-home_tour">
              <div className="p-home_qrHeader">
                <div className="p-home_logoutIc" onClick={logout}>
                  <Icon iconName="logout" size="30" />
                </div>
                <div className="p-home_qrText">
                  <Typography.Text
                    extendClasses="fs-24x32 color-tangerine"
                    fontweight="600"
                  >
                    MÃ QR CỦA TÔI
                  </Typography.Text>
                </div>
                <div className="" />
              </div>
              <div className="p-home_infoUser">
                {/* <div className="p-home_phoneUser">
                  <Typography.Text
                    extendClasses="color-richBlack fs-16x24"
                  >
                    Số điện thoại :
                    {' '}
                    {customer?.phone}
                  </Typography.Text>
                </div> */}
                <div className="p-home_nameUser">
                  <Typography.Text
                    type="span"
                    extendClasses="color-richBlack fs-16x24"
                  >
                    Anh/Chị:
                    {' '}
                  </Typography.Text>
                  <Typography.Text
                    type="span"
                    fontweight="600"
                    extendClasses="color-richBlack fs-20x28"
                  >
                    {customer?.fullName}
                  </Typography.Text>
                </div>
                <div className="p-home_content_qr">
                  <QRCodeSVG
                    size={200}
                    value={customer?.phone || ''}
                  />
                </div>
                <div className="p-home_remind">
                  <Typography.Text
                    textStyle="center"
                    extendClasses="color-greenRyb fs-16x24"
                  >
                    <i>
                      Vui lòng đưa mã QR cho nhân viên tại mỗi khu vực để lưu giữ hành trình
                      {' '}
                      <b>TẾT CHILL</b>
                    </i>
                  </Typography.Text>
                </div>
              </div>
            </div>
          ) : (
            <div className="p-home_journey">
              <Image
                imgSrc={journey}
                ratio={366 / 518}
                alt="journey"
              />
              {boothData?.map((item, idx) => (
                <div
                  className="p-home_tour_itemWrap"
                  key={`p-home_tour_item-${idx.toString()}`}
                >
                  <div
                    className="p-home_tour_item"
                    style={{ maxWidth: `${journeyImgs[idx].maxWidth}px` }}
                    onClick={() => setIndex(idx)}
                  >
                    <Image
                      imgSrc={!item.boothData.active
                        ? journeyImgs[idx].inactive : item.joined
                          ? journeyImgs[idx].joined : journeyImgs[idx].unjoined}
                      alt="image"
                      ratio={journeyImgs[idx].ratio}
                    />
                    <div className="p-home_tour_item_text">
                      <Typography.Text
                        extendClasses="color-white fs-12x16"
                        fontweight="600"
                      >
                        {item.boothData.name}
                      </Typography.Text>
                    </div>
                  </div>
                  {!item.boothData.active ? <div className="p-home_tour_item_disable" /> : ''}
                </div>
              ))}
            </div>
          )}
          {!qrCode ? (
            <div className="p-home_detail">
              <Typography.Text
                textStyle="center"
                extendClasses="color-greenRyb fs-14x20"
              >
                <i>
                  Bấm vào từng khu vực để xem thêm thông tin chi tiết
                </i>
              </Typography.Text>
            </div>
          ) : ''}
        </div>
      </PageLayout>
      <JourneyModal
        isOpen={index === 0 ? true : !!index}
        name={index !== undefined ? formattedBoothData[index].name : ''}
        content={index !== undefined ? formattedBoothData[index].content : ''}
        handleClose={() => setIndex(undefined)}
      />
    </div>
  );

  // if (qrCode && isInit) {
  //   return (
  //     <div className="p-home">
  //       <PageLayout
  //         topLogo
  //         bottomLogo
  //       >
  //         <div className="p-home_content">
  //           <div className="p-home_contentWrap">
  //             <Typography.Text
  //               textStyle="center"
  //               extendClasses="color-greenRyb fs-16x24"
  //             >
  //               ĐĂNG KÝ THÀNH CÔNG
  //             </Typography.Text>
  //             <div className="p-home_textInvite">
  //               <Typography.Text textStyle="center">
  //                 Mời bạn tham gia
  //                 {' '}
  //                 <br />
  //                 {' '}
  //                 hành trình trải nghiệm
  //               </Typography.Text>
  //             </div>
  //             <Button
  //               type="submit"
  //               primaryColor={COLORS.green}
  //               hoveredPrimaryColor={COLORS.greenHover}
  //               variant="primary"
  //               extendClasses="p-home_button"
  //               onClick={() => setIsOpen(true)}
  //             >
  //               <Typography.Text
  //                 fontweight="500"
  //                 extendClasses="fs-16x24"
  //               >
  //                 Hành trình trải nghiệm
  //               </Typography.Text>
  //             </Button>
  //             <Button
  //               type="submit"
  //               primaryColor={COLORS.tangerine}
  //               hoveredPrimaryColor={COLORS.fulvous}
  //               variant="primary"
  //               extendClasses="p-home_button p-home_button_close"
  //               onClick={() => setIsInit(false)}
  //             >
  //               <Typography.Text
  //                 fontweight="500"
  //                 extendClasses="fs-16x24"
  //               >
  //                 Đóng
  //               </Typography.Text>
  //             </Button>
  //           </div>
  //         </div>
  //       </PageLayout>
  //       <CustomModal
  //         isOpen={isOpen}
  //       >
  //         <div className="p-home_qrModal">
  //           <div className="p-home_qrModal_header">
  //             <Typography.Heading
  //               textStyle="center"
  //               extendClasses="fs-12x16 color-greenRyb"
  //               fontweight="700"
  //             >
  //               THÔNG BÁO
  //             </Typography.Heading>
  //           </div>
  //           <div className="p-home_qrModal_description">
  //             <Typography.Text
  //               extendClasses="fs-12x16 color-greenRyb"
  //             >
  //               - Chào mừng bạn đến với chuyến hành trình trải nghiệm TẾT CHILL
  //               cùng Heineken Việt Nam với bốn
  //               thương hiệu từ nhà Strongbow, Tiger Soju, Tiger Platinum và
  //               Edelweiss đến từ Heineken Việt
  //               Nam. Bên cạnh những hoạt động TẾT CHILL đầy thú vị, bạn còn
  //               có cơ hội nhận về hàng ngàn
  //               phần quà hấp dẫn. Còn chần chờ gì mà không nhanh tay khám phá
  //               ngay 5 khu vực trải nghiệm
  //               của TẾT CHILL:
  //               <br />
  //               1. Khu trải nghiệm sản phẩm
  //               <br />
  //               2. Bùa chill giã tay
  //               <br />
  //               3. Tarot chill
  //               <br />
  //               4. Thầy đồ Tết chill
  //               <br />
  //               5. Hái lộc
  //               <br />
  //               - Thời gian hoạt động: từ ngày 13/01/2024 đến ngày 09/02/2024 (có
  //               các khung giờ cụ thể cho từng khu vực).
  //               <br />
  //               - Bạn hãy chọn một điểm đến bất kỳ và bắt đầu lưu giữ hành trình
  //               tuyệt vời của mình tại TẾT CHILL nhé!
  //             </Typography.Text>
  //           </div>
  //           <Button
  //             primaryColor={COLORS.green}
  //             hoveredPrimaryColor={COLORS.greenHover}
  //             variant="primary"
  //             extendClasses="p-home_button"
  //             onClick={() => setQrCode(false)}
  //           >
  //             <Typography.Text
  //               fontweight="500"
  //               extendClasses="fs-16x24"
  //             >
  //               Bắt đầu
  //             </Typography.Text>
  //           </Button>
  //           <Button
  //             primaryColor={COLORS.tangerine}
  //             hoveredPrimaryColor={COLORS.fulvous}
  //             variant="primary"
  //             extendClasses="p-home_button p-home_button_closeModal"
  //             onClick={() => setIsOpen(false)}
  //           >
  //             <Typography.Text
  //               fontweight="500"
  //               extendClasses="fs-16x24"
  //             >
  //               Đóng
  //             </Typography.Text>
  //           </Button>
  //         </div>
  //       </CustomModal>
  //     </div>
  //   );
  // }

  // if (qrCode && !isInit) {
  //   return (
  //     <div className="p-home">
  //       <PageLayout
  //         topLogo
  //         footer={(
  //           <div className="p-home_footer">
  //             <div className="p-home_footer_qr">
  //               <Typography.Text
  //                 fontweight="500"
  //                 extendClasses="color-white"
  //                 onClick={() => setQrCode(true)}
  //               >
  //                 Mã QR của tôi
  //               </Typography.Text>
  //             </div>
  //             <div className="p-home_footer_tour" onClick={() => setQrCode(false)}>
  //               <Typography.Text
  //                 extendClasses="color-white"
  //                 fontweight="500"
  //               >
  //                 Hành trình
  //                 <br />
  //                 trải nghiệm
  //               </Typography.Text>
  //             </div>
  //           </div>
  //         )}
  //       >
  //         <div className="p-home_tour">
  //           <div className="p-home_qrHeader">
  //             <div className="p-home_logoutIc" onClick={logout}>
  //               <Icon iconName="logout" size="30" />
  //             </div>
  //             <div className="p-home_qrText">
  //               <Typography.Text
  //                 extendClasses="fs-24x32 color-tangerine"
  //               >
  //                 QR CỦA TÔI
  //               </Typography.Text>
  //             </div>
  //             <div className="" />
  //           </div>
  //           <div className="p-home_infoUser">
  //             <div className="p-home_phoneUser">
  //               <Typography.Text
  //                 extendClasses="color-richBlack fs-16x24"
  //               >
  //                 Số điện thoại :
  //                 {' '}
  //                 {customer?.phone}
  //               </Typography.Text>
  //             </div>
  //             <div className="p-home_nameUser">
  //               <Typography.Text
  //                 extendClasses="color-richBlack fs-16x24"
  //               >
  //                 Anh/Chị:
  //                 {' '}
  //                 {customer?.fullName}
  //               </Typography.Text>
  //             </div>
  //             <div className="p-home_content_qr">
  //               <QRCodeSVG
  //                 size={200}
  //                 value={customer?.phone || ''}
  //               />
  //             </div>
  //           </div>
  //         </div>
  //       </PageLayout>
  //       <CustomModal
  //         isOpen={isOpen}
  //       >
  //         <div className="p-home_qrModal">
  //           <div className="p-home_qrModal_header">
  //             <Typography.Heading
  //               textStyle="center"
  //               extendClasses="fs-12x16 color-greenRyb"
  //               fontweight="700"
  //             >
  //               THÔNG BÁO
  //             </Typography.Heading>
  //           </div>
  //           <div className="p-home_qrModal_description">
  //             <Typography.Text
  //               extendClasses="fs-12x16 color-greenRyb"
  //             >
  //               - Chào mừng bạn đến với chuyến hành trình trải nghiệm TẾT CHILL
  //               cùng Heineken Việt Nam với bốn
  //               thương hiệu từ nhà Strongbow, Tiger Soju, Tiger Platinum và
  //               Edelweiss đến từ Heineken Việt
  //               Nam. Bên cạnh những hoạt động TẾT CHILL đầy thú vị, bạn còn
  //               có cơ hội nhận về hàng ngàn
  //               phần quà hấp dẫn. Còn chần chờ gì mà không nhanh tay khám phá
  //               ngay 5 khu vực trải nghiệm
  //               của TẾT CHILL:
  //               <br />
  //               1. Khu trải nghiệm sản phẩm
  //               <br />
  //               2. Bùa chill giã tay
  //               <br />
  //               3. Tarot chill
  //               <br />
  //               4. Thầy đồ Tết chill
  //               <br />
  //               5. Hái lộc
  //               <br />
  //               - Thời gian hoạt động: từ ngày 13/01/2024 đến ngày 09/02/2024 (có
  //               các khung giờ cụ thể cho từng khu vực).
  //               <br />
  //               - Bạn hãy chọn một điểm đến bất kỳ và bắt đầu lưu giữ hành trình
  //               tuyệt vời của mình tại TẾT CHILL nhé!
  //             </Typography.Text>
  //           </div>
  //           <Button
  //             primaryColor={COLORS.green}
  //             hoveredPrimaryColor={COLORS.greenHover}
  //             variant="primary"
  //             extendClasses="p-home_button"
  //             onClick={() => setQrCode(false)}
  //           >
  //             <Typography.Text
  //               fontweight="500"
  //               extendClasses="fs-16x24"
  //             >
  //               Bắt đầu
  //             </Typography.Text>
  //           </Button>
  //           <Button
  //             primaryColor={COLORS.tangerine}
  //             hoveredPrimaryColor={COLORS.fulvous}
  //             variant="primary"
  //             extendClasses="p-home_button p-home_button_close"
  //             onClick={() => setIsOpen(false)}
  //           >
  //             <Typography.Text
  //               fontweight="500"
  //               extendClasses="fs-16x24"
  //             >
  //               Đóng
  //             </Typography.Text>
  //           </Button>
  //         </div>
  //       </CustomModal>
  //     </div>
  //   );
  // }

  // return (
  //   <div className="p-home">
  //     <PageLayout
  //       topLogo
  //     >
  //       <div className="p-home_content">
  //         <div className="p-home_header">
  //           <Typography.Heading
  //             textStyle="center"
  //             extendClasses="color-tangerine fs-24x32"
  //           >
  //             HÀNH TRÌNH TRẢI NGHIỆM
  //           </Typography.Heading>
  //         </div>
  //         <div className="p-home_journey">
  //           <Image
  //             imgSrc={journey}
  //             ratio={365 / 535}
  //             alt="journey"
  //           />
  //           <div className="p-home_journey_hailoc" onClick={() => setIndex(1)}>
  //             <Image
  //               imgSrc={hailoc}
  //               ratio={123 / 30}
  //               alt="hailoc"
  //             />
  //           </div>
  //           <div className="p-home_journey_buachill" onClick={() => setIndex(2)}>
  //             <Image
  //               imgSrc={buachill}
  //               ratio={176 / 31}
  //               alt="buachill"
  //             />
  //           </div>
  //           <div className="p-home_journey_tarot" onClick={() => setIndex(3)}>
  //             <Image
  //               imgSrc={tarot}
  //               ratio={138 / 31}
  //               alt="buachill"
  //             />
  //           </div>
  //           <div className="p-home_journey_tetchill" onClick={() => setIndex(4)}>
  //             <Image
  //               imgSrc={tetchill}
  //               ratio={179 / 31}
  //               alt="tetchill"
  //             />
  //           </div>
  //           <div className="p-home_journey_khutrainghiem" onClick={() => setIndex(5)}>
  //             <Image
  //               imgSrc={khutrainghiem}
  //               ratio={248 / 31}
  //               alt="khutrainghiem"
  //             />
  //           </div>
  //         </div>
  //         <div className="p-home_journeyFooter">
  //           <div className="p-home_journeyFooter_qr">
  //             <Typography.Text
  //               fontweight="500"
  //               extendClasses="color-white"
  //               onClick={() => setQrCode(true)}
  //             >
  //               Mã QR của tôi
  //             </Typography.Text>
  //           </div>
  //           <div className="p-home_journeyFooter_tour">
  //             <Typography.Text
  //               extendClasses="color-white"
  //               fontweight="500"
  //               onClick={() => setQrCode(false)}
  //             >
  //               Hành trình
  //               <br />
  //               trải nghiệm
  //             </Typography.Text>
  //           </div>
  //         </div>
  //       </div>
  //     </PageLayout>
  //     <JourneyModal
  //       isOpen={!!index}
  //       name={index ? jorneyList[index].name : ''}
  //       content={index ? jorneyList[index].content : ''}
  //       handleClose={() => setIndex(undefined)}
  //     />
  //   </div>
  // );
};

export default Home;
