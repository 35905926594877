import React from 'react';
import { useSearchParams } from 'react-router-dom';

import que1 from 'assets/images/20cauque/que1.jpg';
import que10 from 'assets/images/20cauque/que10.jpg';
import que11 from 'assets/images/20cauque/que11.jpg';
import que12 from 'assets/images/20cauque/que12.jpg';
import que13 from 'assets/images/20cauque/que13.jpg';
import que14 from 'assets/images/20cauque/que14.jpg';
import que15 from 'assets/images/20cauque/que15.jpg';
import que16 from 'assets/images/20cauque/que16.jpg';
import que17 from 'assets/images/20cauque/que17.jpg';
import que18 from 'assets/images/20cauque/que18.jpg';
import que19 from 'assets/images/20cauque/que19.jpg';
import que2 from 'assets/images/20cauque/que2.jpg';
import que20 from 'assets/images/20cauque/que20.jpg';
import que3 from 'assets/images/20cauque/que3.jpg';
import que4 from 'assets/images/20cauque/que4.jpg';
import que5 from 'assets/images/20cauque/que5.jpg';
import que6 from 'assets/images/20cauque/que6.jpg';
import que7 from 'assets/images/20cauque/que7.jpg';
import que8 from 'assets/images/20cauque/que8.jpg';
import que9 from 'assets/images/20cauque/que9.jpg';
import useWindowDimensions from 'hooks/useWindowDemensions';

type GreenCardType = {
  code: string;
  img: string;
};
const imgArr: GreenCardType[] = [
  {
    code: '2UU4',
    img: que1
  },
  {
    code: 'FQ65',
    img: que2
  },
  {
    code: 'YUNQ',
    img: que3
  },
  {
    code: 'HVR8',
    img: que4
  },
  {
    code: 'HZFY',
    img: que5
  },
  {
    code: 'SK30',
    img: que6
  },
  {
    code: 'AM5Y',
    img: que7
  },
  {
    code: 'JXJL',
    img: que8
  },
  {
    code: 'RDPN',
    img: que9
  },
  {
    code: '2AKD',
    img: que10
  },
  {
    code: 'HORI',
    img: que11
  },
  {
    code: 'GTHM',
    img: que12
  },
  {
    code: '2NM5',
    img: que13
  },
  {
    code: '4F97',
    img: que14
  },
  {
    code: '6AQJ',
    img: que15
  },
  {
    code: '921P',
    img: que16
  },
  {
    code: 'CFAP',
    img: que17
  },
  {
    code: 'YIPI',
    img: que18
  },
  {
    code: '2JPY',
    img: que19
  },
  {
    code: '0C0F',
    img: que20
  },
];
const MessageImage: React.FC = () => {
  const { width, height } = useWindowDimensions();
  const [searchParams] = useSearchParams();
  const img = searchParams.get('image');
  return (
    <div
      className="p-messageImage"
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <img src={imgArr.find((item) => item.code === img)?.img || ''} alt="message" />
    </div>
  );
};

export default MessageImage;
