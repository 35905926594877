import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  Button, Input, Loading, Typography
} from 'tfc-components';
import * as yup from 'yup';

import FormField from 'components/organisms/FormField';
import PageLayout from 'components/organisms/PageLayout';
import { salesPersonLoginService } from 'services/authenticate';
import { IPGLogin } from 'services/authenticate/types';
import returnValidateErrorMessages from 'services/common/errors';
import { setAccessToken, setLocalStorage } from 'services/common/storage';
import { getPGProfileAsync } from 'store/authenticate';
import { useAppDispatch } from 'store/hooks';
import {
  COLORS, LOCALSTORAGE_KEY, ROUTES_PATH, USER_ROLE
} from 'utils/constants';
import mapModifiers from 'utils/functions';

const fieldToText = (field: string) => {
  switch (field) {
    case 'username':
      return 'Tài khoản';
    case 'password':
      return 'Mật khẩu';
    default:
      return '';
  }
};
const loginSchema: yup.ObjectSchema<IPGLogin> = yup.object().shape({
  username: yup.string().required('Vui lòng cung cấp thông tin'),
  password: yup.string().required('Vui lòng cung cấp thông tin'),
});

const PGLogin: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const method = useForm<IPGLogin>({
    mode: 'onChange',
    resolver: yupResolver(loginSchema),
    defaultValues: {
      username: '',
      password: '',
    },
  });
  const { mutate, isLoading } = useMutation('pgLogin', salesPersonLoginService, {
    onSuccess(data) {
      setLoading(true);
      setAccessToken(data);
      setLocalStorage(LOCALSTORAGE_KEY.ROLE, USER_ROLE.PG);
      dispatch(getPGProfileAsync()).unwrap().then(() => {
        navigate(ROUTES_PATH.PG);
      }).finally(() => {
        setLoading(false);
      });
    },
    onError(error: any) {
      if (error.length > 0) {
        error.forEach((err: ValidateError) => {
          if (err.code === 'auth.failed') {
            method.setError(
              'password',
              { message: 'Thông tin đăng nhập không chính xác' }
            );
          } else {
            method.setError(
              err.field,
              { message: returnValidateErrorMessages(err, fieldToText(err.field)) }
            );
          }
        });
      } else {
        method.setError(
          'password',
          { message: 'Đã có lỗi xảy ra.<br />Vui lòng thực hiện lại' }
        );
      }
    },
  });
  const onSubmit = (data: IPGLogin) => {
    mutate(data);
  };
  return (
    <div className="p-login">
      <PageLayout topLogo bottomLogo>
        <div className="p-login_pgHeader">
          <Typography.Heading
            textStyle="center"
            extendClasses="color-tangerine fs-24x32"
          >
            ĐĂNG NHẬP
          </Typography.Heading>
        </div>
        <div className="p-login_contentPg">
          <FormProvider {...method}>
            <FormField label="Tên tài khoản">
              <Controller
                name="username"
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Input
                      {...field}
                      bordered
                      type="text"
                      placeholder="Nhập tên tài khoản"
                      extendClasses={mapModifiers('p-login_field', error && 'error')}
                    />
                    {error && (
                      <div className="form-error-message">{error.message}</div>
                    )}
                  </>
                )}
              />
            </FormField>
            <FormField label="Mật khẩu" className="u-mt-12">
              <Controller
                name="password"
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Input
                      {...field}
                      bordered
                      type="password"
                      placeholder="Nhập mật khẩu"
                      extendClasses={mapModifiers('p-login_field', error && 'error')}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          method.handleSubmit(onSubmit)();
                        }
                      }}
                    />
                    {error && (
                      <div className="form-error-message">{error.message}</div>
                    )}
                  </>
                )}
              />
            </FormField>
            <Button
              type="submit"
              primaryColor={COLORS.tangerine}
              hoveredPrimaryColor={COLORS.tangerine}
              variant="primary"
              extendClasses="p-login_submit"
              loading={isLoading || loading}
              disabled={isLoading || loading}
              loadingIndicator={<Loading.CircleDashed color={COLORS.white} />}
              onClick={method.handleSubmit(onSubmit)}
            >
              <Typography.Text fontweight="500">Đăng nhập</Typography.Text>
            </Button>
          </FormProvider>
        </div>
      </PageLayout>
    </div>
  );
};

export default PGLogin;
