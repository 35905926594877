/* eslint-disable react/react-in-jsx-scope */
import { Button, Typography } from 'tfc-components';

import CustomModal from 'components/organisms/Modal';
import { COLORS } from 'utils/constants';

type JourneyModalType = {
  name: string
  content: string
  isOpen: boolean
  handleClose: () => void;
};

const JourneyModal: React.FC<JourneyModalType> = ({
  name, content, isOpen, handleClose
}) => (
  <CustomModal isOpen={isOpen}>
    <div className="p-home_journeyModal">
      <div className="p-home_journeyModal_header">
        <Typography.Text
          textStyle="center"
          extendClasses="fs-16x24 color-greenRyb"
          fontweight="700"
        >
          {name}
        </Typography.Text>
      </div>
      <div className="p-home_journeyModal_content">
        <div className="fs-12x16" dangerouslySetInnerHTML={{ __html: content || '' }} />
      </div>
      <Button
        primaryColor={COLORS.tangerine}
        hoveredPrimaryColor={COLORS.tangerine}
        variant="primary"
        extendClasses="p-home_button p-home_button_close"
        onClick={handleClose}
      >
        <Typography.Text
          fontweight="500"
          extendClasses="fs-16x24"
        >
          Đóng
        </Typography.Text>
      </Button>
    </div>
  </CustomModal>
);

export default JourneyModal;
