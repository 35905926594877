import React, {
  useCallback
} from 'react';
import { animated, useSpring } from 'react-spring';
import { Button, Typography } from 'tfc-components';

import marker from 'assets/images/draw-marker.png';
import Circle from 'assets/images/element-07.png';
import Wheel from 'assets/images/element-08.png';
import { Gift, LuckyDrawData } from 'services/pg/types';
import { COLORS } from 'utils/constants';
import mapModifiers from 'utils/functions';

interface LuckyWheelProps {
  loading?: boolean;
  allowDraw?: boolean;
  gifts: Gift[];
  onClick?: () => Promise<LuckyDrawData | null>;
}

const WHEEL_OFFSET = 45;
const DURATION = 5000;

const LuckyWheel: React.FC<LuckyWheelProps> = ({
  onClick, allowDraw, gifts, loading
}) => {
  const [props, api] = useSpring(() => ({
    rotate: 0,
  }));

  const draw = useCallback(async () => {
    try {
      const value = await onClick?.();

      if (value) {
        const prizeId: number = gifts.findIndex((gift) => gift.code === value?.gift.code);

        const destOffset = 360 - (WHEEL_OFFSET * prizeId + WHEEL_OFFSET / 2);

        api.start({
          from: {
            rotate: 360,
          },
          to: {
            rotate: 360 * 10 + destOffset,
          },
          config: {
            duration: DURATION,
            easing: (t) => 2 * t - t ** 2,
          },
        });
      }

      return null;
    } catch (error) {
      return error;
    }
  }, [onClick, gifts, api]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCount((prevCount) => {
  //       if (prevCount === 0) {
  //         return prevCount;
  //       }
  //       return prevCount - 1;
  //     });
  //   }, 1000);

  //   if (count === 0) {
  //     clearInterval(interval);
  //     if (onTimeout) {
  //       onTimeout();
  //     }
  //   }

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [count, onTimeout]);

  return (
    <div className="t-luckyWheel">
      <div className="circle">
        <img src={Circle} alt="circle" />
        <div className="t-luckyWheel-wheel">
          <animated.img src={Wheel} alt="wheel" style={props} />
        </div>
        <div className="t-luckyWheel_marker">
          <img src={marker} alt="marker" />
        </div>
      </div>
      <div style={{
        textAlign: 'center', marginTop: '12px', position: 'relative', zIndex: 3
      }}
      >
        <div className="t-luckyWheel_button">
          <Button
            type="submit"
            primaryColor={COLORS.green}
            hoveredPrimaryColor={COLORS.green}
            variant="primary"
            extendClasses={mapModifiers('p-login_submit', (!allowDraw || loading) && 'disabled')}
            disabled={!allowDraw || loading}
            onClick={draw}
          >
            <Typography.Text
              fontweight="500"
              extendClasses="fs-16x24"
            >
              Quay ngay
            </Typography.Text>
          </Button>
        </div>
      </div>
    </div>
  );
};

LuckyWheel.defaultProps = {
};

export default LuckyWheel;
