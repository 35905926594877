import { BoothData } from './types';

import axiosInstance from 'services/common/instance';

export const getBoothDataService = async (): Promise<BoothData[]> => {
  const response = await axiosInstance.get('customers/booths');
  return response.data.data;
};

export const placeholder = null;
