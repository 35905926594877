import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from 'tfc-components';

import Icon from 'components/atoms/Icon';
import PageLayout from 'components/organisms/PageLayout';
import { clearLocalStorage, getLocalStorage } from 'services/common/storage';
import { pgGetGiftService } from 'services/pg';
import { useAppSelector } from 'store/hooks';
import {
  COLORS, LOCALSTORAGE_KEY, ROUTES_PATH, USER_ROLE
} from 'utils/constants';
import { formatDateDDMMYYYY } from 'utils/functions';

const PG: React.FC = () => {
  const navigate = useNavigate();
  const pg = useAppSelector((state) => state.authenticate.pg);
  const [gift, setGift] = useState(false);
  const { data: giftsData } = useQuery('pgGifts', pgGetGiftService);
  const giftsMemo = React.useMemo(() => ({
    ipadLuckyDraw: giftsData?.filter((item) => item.giftData.type === 'ipadLuckyDraw').map((item) => ({ name: item.giftData.name, quantity: item.giftData.quotaPerDay - item.usedToday })),
    spLuckyDraw: giftsData?.filter((item) => item.giftData.type === 'spLuckyDraw').map((item) => ({ name: item.giftData.name, quantity: item.giftData.quotaPerDay - item.usedToday }))
  }), [giftsData]);
  const logout = useCallback(() => {
    clearLocalStorage();
    navigate(ROUTES_PATH.PGLOGIN);
  }, [navigate]);
  useEffect(() => {
    const role = getLocalStorage(LOCALSTORAGE_KEY.ROLE);
    if (role && role !== USER_ROLE.PG) {
      logout();
    }
  }, [logout]);
  return (
    <div className="p-home">
      <PageLayout
        topLogo
        bottomLogo
      >
        <div className="p-home_headerPg">
          <div className="p-home_logoutPg" onClick={() => (gift ? setGift(false) : logout())}>
            <Icon iconName={gift ? 'back' : 'logout'} size="36" />
          </div>
          <Typography.Text
            textStyle="center"
            extendClasses="color-tangerine fs-24x32"
          >
            THÔNG TIN CHUNG
          </Typography.Text>
          <div />
        </div>
        <div className="p-home_contentPgHome">
          <div className="p-home_content_field flex">
            <Typography.Text fontweight="400">Ngày:</Typography.Text>
            <Typography.Text fontweight="600">{formatDateDDMMYYYY(new Date())}</Typography.Text>
          </div>
          <div className="p-home_content_field flex">
            <Typography.Text fontweight="400">Tài khoản:</Typography.Text>
            <Typography.Text fontweight="600">{pg?.username}</Typography.Text>
          </div>
          <div className="p-home_content_field flex">
            <Typography.Text fontweight="400">Booth:</Typography.Text>
            <Typography.Text fontweight="600">{pg?.booth.name}</Typography.Text>
          </div>
          {gift ? (
            <>
              <Typography.Text fontweight="700" extendClasses="p-pg_gift">Vòng quay may mắn</Typography.Text>
              {giftsMemo.spLuckyDraw?.map((item) => (
                <div className="p-pg_line">
                  <Typography.Text fontweight="500">
                    {item.name}
                    :
                  </Typography.Text>
                  <Typography.Text fontweight="700" extendClasses={item.quantity === 0 ? 'color-red' : 'color-black'}>{item.quantity}</Typography.Text>
                </div>
              ))}
              <Typography.Text fontweight="700" extendClasses="p-pg_gift">Lắc quẻ</Typography.Text>
              {giftsMemo.ipadLuckyDraw?.map((item) => (
                <div className="p-pg_line">
                  <Typography.Text fontweight="500">
                    {item.name}
                    :
                    {' '}
                  </Typography.Text>
                  <Typography.Text fontweight="700" extendClasses={item.quantity === 0 ? 'color-red' : 'color-black'}>{item.quantity}</Typography.Text>
                </div>
              ))}
            </>
          )
            : (
              <>
                <Button
                  type="submit"
                  primaryColor={COLORS.tangerine}
                  hoveredPrimaryColor={COLORS.tangerine}
                  variant="primary"
                  extendClasses="p-home_scan"
                  onClick={() => navigate(ROUTES_PATH.PGSCAN)}
                >
                  <Typography.Text fontweight="500">Quét mã QR</Typography.Text>
                </Button>
                {pg?.booth.isLastGame && (
                  <>
                    <Button
                      type="submit"
                      primaryColor={COLORS.tangerine}
                      hoveredPrimaryColor={COLORS.tangerine}
                      variant="primary"
                      extendClasses="p-home_scan bill"
                      onClick={() => navigate(`${ROUTES_PATH.PGSCAN}?useBill=yes`)}
                    >
                      <Typography.Text fontweight="500">Nhập thông tin hóa đơn</Typography.Text>
                    </Button>
                    <Button
                      type="submit"
                      primaryColor={COLORS.tangerine}
                      hoveredPrimaryColor={COLORS.tangerine}
                      variant="primary"
                      extendClasses="p-home_scan bill"
                      onClick={() => navigate(ROUTES_PATH.LUCKYDRAW)}
                    >
                      <Typography.Text fontweight="500">Vòng quay may mắn</Typography.Text>
                    </Button>
                  </>
                )}
                {(pg?.booth.isLastGame || pg?.booth.isLuckyDraw) && (
                  <Button
                    type="submit"
                    primaryColor={COLORS.tangerine}
                    hoveredPrimaryColor={COLORS.tangerine}
                    variant="primary"
                    extendClasses="p-home_scan bill"
                    onClick={() => setGift(true)}
                  >
                    <Typography.Text fontweight="500">Xem quà tặng còn lại</Typography.Text>
                  </Button>
                )}
              </>
            )}

        </div>
      </PageLayout>
    </div>
  );
};

export default PG;
