import {
  GreenCardData, LuckyDrawData, LuckyDrawParams, UploadImageIpadParams
} from './types';

import axiosInstance from 'services/common/instance';

export const getGreenCardService = async (greenCardCode: string)
  : Promise<GreenCardData> => {
  const response = await axiosInstance.post('ipads/get-green-card', { greenCardCode });
  return response.data.data;
};

export const checkLuckyDrawService = async (phone: string): Promise<boolean> => {
  const response = await axiosInstance.post('ipads/check-lucky-draw', { phone });
  return response.data.data.canDraw;
};

export const luckyDrawService = async (data: LuckyDrawParams): Promise<LuckyDrawData> => {
  const response = await axiosInstance.post('ipads/lucky-draw', data);
  return response.data.data;
};

export const ipadUploadImageService = async (
  data: UploadImageIpadParams
): Promise<string[]> => {
  const response = await axiosInstance.post('ipads/upload-images', data);
  return response.data.data.images;
};
