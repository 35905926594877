export const COLORS = {
  white: '#ffffff',
  oxfordBlue: '#011e41',
  deepKoamaru: '#344b67',
  red: '#ed1c24',
  redHorver: '#d2080e',
  tangerine: '#ed8b00',
  fulvous: '#e58700',
  green: '#5e9b31',
  greenHover: '#86c955',
};

export const ROUTES_PATH = {
  HOME: '/',
  LOGIN: '/login',
  PG: '/pg',
  PGLOGIN: '/pg/login',
  PGSCAN: '/pg/scan',
  LUCKYDRAW: '/pg/luckydraw',
  BOOTHLOGIN: '/booth/login',
  BOOTH: '/booth',
  REVIEWMESSAGE: '/review-message'
};

export const URL_CONST = {
  MEDIA_FILE_UPLOAD_CHUNK: '/api/v1/files/upload-chunk',
};

export const LOCALSTORAGE_KEY = {
  ACCESSTOKEN: 'MOFT_ACCESSTOKEN',
  ROLE: 'MOFT_ROLE',
  USER_UUID: 'MOFT_USER_UUID',
};

export const USER_ROLE = {
  CUSTOMER: 'customer',
  PG: 'pg',
  BOOTH: 'booth'
};
