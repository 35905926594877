import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { getAccessToken, getLocalStorage } from 'services/common/storage';
import { getBoothProfileAsync, getCustomerProfileAsync, getPGProfileAsync } from 'store/authenticate';
import { useAppDispatch } from 'store/hooks';
import { LOCALSTORAGE_KEY, USER_ROLE } from 'utils/constants';

const MainLayout: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const token = getAccessToken();
    const role = getLocalStorage(LOCALSTORAGE_KEY.ROLE);
    if (token) {
      switch (role) {
        case USER_ROLE.CUSTOMER:
          dispatch(getCustomerProfileAsync());
          break;
        case USER_ROLE.PG:
          dispatch(getPGProfileAsync());
          break;
        case USER_ROLE.BOOTH:
          dispatch(getBoothProfileAsync());
          break;
        default:
          break;
      }
    }
  }, [dispatch]);
  return (
    <main>
      <Outlet />
    </main>
  );
};
export default MainLayout;
