// eslint-disable-next-line import/no-extraneous-dependencies
import { FpjsClient } from '@fingerprintjs/fingerprintjs-pro-react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  Button, Input, Loading, Typography, Image
} from 'tfc-components';
import * as yup from 'yup';

import logo from 'assets/images/logo.png';
import Checkbox from 'components/atoms/Checkbox';
import FormField from 'components/organisms/FormField';
import CustomModal from 'components/organisms/Modal';
import PageLayout from 'components/organisms/PageLayout';
import { customerLoginService } from 'services/authenticate';
import returnValidateErrorMessages from 'services/common/errors';
import { setAccessToken, setLocalStorage } from 'services/common/storage';
import { getCustomerProfileAsync } from 'store/authenticate';
import { useAppDispatch } from 'store/hooks';
import {
  COLORS, LOCALSTORAGE_KEY, ROUTES_PATH, USER_ROLE
} from 'utils/constants';
import mapModifiers from 'utils/functions';

const fieldToText = (field: string) => {
  switch (field) {
    case 'phone':
      return 'Số điện thoại';
    case 'fullName':
      return 'Họ và tên';
    default:
      return '';
  }
};

type ILoginForm = {
  fullName: string;
};
const loginSchema: yup.ObjectSchema<ILoginForm> = yup.object().shape({
  fullName: yup.string().required('Vui lòng cung cấp thông tin'),
});

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenInit, setIsOpenInit] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [condition, setCondition] = React.useState(false);
  const [isTerms, setIsTerms] = React.useState(false);
  const [loginSuccess, setLoginSuccess] = React.useState(false);
  const [accepted, setAccepted] = React.useState(false);
  const method = useForm<ILoginForm>({
    mode: 'onChange',
    resolver: yupResolver(loginSchema),
    defaultValues: {
      fullName: '',
    },
  });
  const { mutate, isLoading } = useMutation('customerLogin', customerLoginService, {
    onSuccess(data) {
      setAccessToken(data);
      setLocalStorage(LOCALSTORAGE_KEY.ROLE, USER_ROLE.CUSTOMER);
      dispatch(getCustomerProfileAsync());
      setCondition(true);
    },
    onError(error: any) {
      if (error.length > 0) {
        error.forEach((err: ValidateError) => {
          method.setError(
            err.field,
            { message: returnValidateErrorMessages(err, fieldToText(err.field)) }
          );
        });
      } else {
        method.setError(
          'fullName',
          { message: 'Đã có lỗi xảy ra. Vui lòng thực hiện lại' }
        );
      }
    },
  });
  const onSubmit = (data: ILoginForm) => {
    const deviceId = localStorage.getItem(LOCALSTORAGE_KEY.USER_UUID);
    if (deviceId) {
      mutate({
        fullName: data.fullName,
        phone: deviceId,
      });
    } else {
      setLoading(true);
      const provider = new FpjsClient();
      provider.init({
        apiKey: 'h1i02cuILg5thm9XrGVr',
        region: 'ap',
        scriptUrlPattern: '/loader.js',
      }).then(() => {
        provider.getVisitorData().then((visitor) => {
          localStorage.setItem(LOCALSTORAGE_KEY.USER_UUID, visitor.visitorId);
          mutate({
            fullName: data.fullName,
            phone: visitor.visitorId,
          });
        });
      }).finally(() => {
        setLoading(false);
      });
    }
  };
  return (
    <div className="p-login">
      <PageLayout topLogo bottomLogo>
        {loginSuccess ? (
          <div className="p-home_contentWrap">
            <Typography.Text
              textStyle="center"
              extendClasses="color-greenRyb fs-24x32"
            >
              ĐĂNG KÝ THÀNH CÔNG
            </Typography.Text>
            <div className="p-home_textInvite">
              <Typography.Text textStyle="center" extendClasses="fs-20x28">
                Mời bạn bước vào chuyến hành trình trải nghiệm
                {' '}
                <b className="color-greenRyb">TẾT CHILL</b>
              </Typography.Text>
            </div>
            <Button
              type="submit"
              primaryColor={COLORS.green}
              hoveredPrimaryColor={COLORS.green}
              variant="primary"
              extendClasses="p-home_button"
              onClick={() => setIsOpen(true)}
            >
              <Typography.Text
                fontweight="500"
                extendClasses="fs-16x24"
              >
                Hành trình trải nghiệm
              </Typography.Text>
            </Button>
            <Button
              type="submit"
              primaryColor={COLORS.tangerine}
              hoveredPrimaryColor={COLORS.tangerine}
              variant="primary"
              extendClasses="p-home_button p-home_button_close"
              onClick={() => navigate(`${ROUTES_PATH.HOME}?qr=yes`)}
            >
              <Typography.Text
                fontweight="500"
                extendClasses="fs-16x24"
              >
                Đóng
              </Typography.Text>
            </Button>
          </div>
        ) : (
          <>
            <div className="p-login_header">
              <Typography.Heading
                textStyle="center"
                extendClasses="color-tangerine fs-24x32"
              >
                VUI LÒNG NHẬP
                {' '}
                <br />
                {' '}
                THÔNG TIN CỦA BẠN
              </Typography.Heading>
            </div>
            <div className="p-login_content">
              <FormProvider {...method}>
                <FormField label="Họ và tên">
                  <Controller
                    name="fullName"
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <Input
                          {...field}
                          bordered
                          type="text"
                          placeholder="Nhập họ và tên"
                          extendClasses={mapModifiers('p-login_field', error && 'error')}
                        />
                        {error && (
                          <div className="form-error-message">{error.message}</div>
                        )}
                      </>
                    )}
                  />
                </FormField>
                <div className="p-login_condition_content">
                  <Typography.Text
                    extendClasses="color-greenRyb
                    p-login_condition_contentText"
                  >
                    <i style={{ textAlign: 'center' }}>
                      Mỗi khách hàng sẽ nhận 01 mã QR riêng để ghi dấu hành trình tại
                      {' '}
                      <b>TẾT CHILL</b>
                      .
                    </i>
                  </Typography.Text>
                </div>
                {/* <FormField label="Số điện thoại" className="u-mt-20">
                  <Controller
                    name="phone"
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <Input
                          {...field}
                          bordered
                          type="number"
                          placeholder="Nhập số điện thoại"
                          extendClasses={mapModifiers('p-login_field', error && 'error')}
                          onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                              method.handleSubmit(onSubmit)();
                            }
                          }}
                        />
                        {error && (
                          <div className="form-error-message">{error.message}</div>
                        )}
                      </>
                    )}
                  />
                </FormField> */}
                <Button
                  type="submit"
                  primaryColor={COLORS.tangerine}
                  hoveredPrimaryColor={COLORS.tangerine}
                  variant="primary"
                  loading={isLoading || loading}
                  disabled={isLoading || loading}
                  loadingIndicator={<Loading.CircleDashed color={COLORS.white} />}
                  extendClasses="p-login_submit"
                  onClick={method.handleSubmit(onSubmit)}
                >
                  <Typography.Text
                    fontweight="500"
                    extendClasses="fs-16x24"
                  >
                    Tạo mã QR code
                  </Typography.Text>
                </Button>
              </FormProvider>
            </div>
          </>
        )}
      </PageLayout>
      <CustomModal isOpen={isOpenInit}>
        <div className="p-login_condition">
          <div className="p-login_condition_content">
            <Typography.Text>
              <p style={{ display: 'flex', justifyContent: 'center' }}>
                <b className="color-greenRyb fs-20x28" style={{ textAlign: 'center' }}>CHÀO MỪNG</b>
              </p>
              <p style={{ textAlign: 'center', marginTop: '4px' }}>
                &quot;Tết này vẫn giống Tết xưa,
                <br />
                Vẫn còn con nít, vẫn cần
                {' '}
                <b className="color-greenRyb">TẾT CHILL</b>
                &quot;
              </p>
              <br />
              Chào mừng bạn đến với trải nghiệm
              {' '}
              <b className="color-greenRyb">TẾT CHILL</b>
              {' '}
              cùng bốn nhãn
              hàng:
              {' '}
              <b>Strongbow</b>
              ,
              {' '}
              <b>Tiger Soju</b>
              ,
              {' '}
              <b>Tiger Platinum</b>
              {' '}
              và
              {' '}
              <b>Edelweiss</b>
              {' '}
              từ nhà Heineken Việt Nam.
              {' '}
              <b className="color-greenRyb">TẾT CHILL</b>
              {' '}
              mời bạn bước chân vào chuyến hành trình lưu lạc ở xứ sở - nơi tái hiện
              không gian rực rỡ sắc màu với những phong tục Tết mang đậm văn hóa của Tết Việt.
              <br />
              <br />
              Đừng chần chờ gì mà hãy nhanh tay bấm vào nút bắt đầu phía dưới để du
              xuân cùng
              {' '}
              <b className="color-greenRyb">TẾT CHILL</b>
              {' '}
              nhé!
            </Typography.Text>
          </div>
          <div className="p-homeConditon_content_button">
            <Button
              type="submit"
              primaryColor={COLORS.green}
              hoveredPrimaryColor={COLORS.greenHover}
              variant="primary"
              // disabled={!accepted}
              extendClasses="p-login_submit"
              onClick={() => setIsOpenInit(false)}
            >
              <Typography.Text
                fontweight="500"
                extendClasses="fs-16x24"
              >
                Bắt đầu
              </Typography.Text>
            </Button>
          </div>
        </div>
      </CustomModal>
      <CustomModal isOpen={condition}>
        {!isTerms ? (
          <div className="p-login_condition">
            <div className="p-login_logo18">
              <Image
                imgSrc={logo}
                ratio={175 / 110}
                alt="logo"
              />
            </div>
            <div className="p-login_condition_content">
              <Typography.Text
                fontweight="600"
                extendClasses="fs-20x28"
              >
                <p style={{ textAlign: 'center' }}>
                  <b>Bạn đã đủ 18 tuổi chưa?</b>
                </p>
              </Typography.Text>
            </div>
            <Checkbox onChange={(e) => setAccepted(e.currentTarget.checked)}>
              <Typography.Text
                extendClasses="p-login_conditionText fs-14x20"
              >
                Tôi đồng ý cho Heineken Vietnam xử lý các thông tin cá nhân của
                tôi cho mục đích tiếp thị, phân tích nội bộ, chăm sóc khách hàng
                và các mục đích khác, cụ thể xem chi tiết ở
                {' '}
                <b>
                  Thông báo quyền riêng
                  tư
                </b>
                {' '}
                và
                {' '}
                <b style={{ textDecoration: 'underline' }} onClick={() => setIsTerms(true)}>
                  Điều khoản & điều kiện.
                </b>
              </Typography.Text>
            </Checkbox>
            <div className="p-homeConditon_content_button">
              <Button
                type="submit"
                primaryColor={COLORS.green}
                hoveredPrimaryColor={COLORS.greenHover}
                variant="primary"
                disabled={!accepted}
                extendClasses="p-login_submit"
                onClick={() => {
                  setLoginSuccess(true);
                  setCondition(false);
                }}
              >
                <Typography.Text
                  fontweight="500"
                  extendClasses="fs-16x24"
                >
                  Tôi đủ 18
                </Typography.Text>
              </Button>
            </div>
            <div className="p-homeConditon_content_button">
              <Button
                primaryColor={COLORS.tangerine}
                hoveredPrimaryColor={COLORS.fulvous}
                variant="primary"
                disabled={!accepted}
                extendClasses="p-login_submit under"
                onClick={() => {
                  setCondition(false);
                  setAccepted(false);
                }}
              >
                <Typography.Text
                  fontweight="500"
                  extendClasses="fs-16x24"
                >
                  Tôi chưa 18
                </Typography.Text>
              </Button>
            </div>
          </div>
        ) : (
          <div className="p-login_condition">
            <div className="p-login_condition_content">
              <Typography.Text>
                <b className="color-greenRyb">ĐIỂU KHOẢN & ĐIỀU KIỆN</b>
                <br />
                <br />
                <b className="color-greenRyb">THỂ LỆ CHƯƠNG TRÌNH KHUYẾN MẠI</b>
                <br />
                <br />
                <b>Thời gian khuyến mại</b>
                : Từ ngày 13/01/2024 đến ngày 09/02/2024.
                <br />
                <b>Hàng hóa dịch vụ khuyến mại</b>
                : Sản phẩm thuộc nhãn hàng Strongbow, Tiger Soju, Tiger Platinum và Edelweiss.
                <br />
                <b>Phạm vi khuyến mại</b>
                : Siêu thị Lotte Nam Sài Gòn (469 Nguyễn Hữu Thọ, Quận 7, Thành phố Hồ Chí Minh)
                <br />
                <b>Hình thức khuyến mại</b>
                : Tham gia 5 khu vực trải nghiệm và nhận quà khuyến mãi.
                <br />
                <b>Đối tượng hưởng khuyến mại</b>
                : Áp dụng cho Khách hàng (KH) trên 18 tuổi tham gia trải nghiệm sản
                phẩm tại siêu thị ở phần phạm vi khuyến mại.
                <br />
                <b>Điều kiện tham gia chương trình</b>
                : KH đủ 18 tuổi sẽ được tham gia trải nghiệm toàn bộ khu vực tại
                {' '}
                <b className="color-greenRyb">TẾT CHILL</b>
                .
                <br />
                <br />
                <b className="color-greenRyb">ĐIỀU KIỆN NHẬN GIẢI</b>
                <br />
                - Nội dung thông báo trúng giải hợp lệ.
                <br />
                - Hóa đơn và mã quà tặng chỉ có giá trị đổi quà trong ngày và trong
                thời gian làm việc của nhân viên tiếp thị các nhãn hàng
                Strongbow, Tiger Soju, Tiger Platinum và Edelweiss thuộc Heineken Việt Nam (HVN).
                <br />
                - Điều kiện nhận giải đối với khu vực Hái lộc:
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;+ Hóa đơn mua hàng có đáp ứng điều
                kiện tham gia phải được đóng mộc.
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;+ Mỗi hóa đơn mua hàng có tối thiểu 06 lon/chai của các nhãn
                hàng Strongbow, Tiger Soju, Tiger Platinum và Edelweiss chỉ được tham gia Hái lộc 01
                lần/ hóa đơn và không giới hạn lượt chơi trong ngày, số lượt chơi tương ứng với số
                hóa đơn hợp lệ.
                <br />
                - Chương trình không áp dụng cho khách hàng mua sỉ, mua hàng
                qua điện thoại/online, nhân viên siêu thị, nhân viên HVN và
                nhân viên thực hiện chương trình khuyến mãi.
                <br />
                - Quà tặng không được quy đổi thành tiền mặt. Số lượng quà tặng có
                hạn, chương trình có thể kết thúc sớm mà không cần thông báo trước
                khi hết quà tặng.
                <br />
                - Trong mọi trường hợp tranh chấp xảy ra liên quan tới chương
                trình, quyền quyết định cuối cùng sẽ thuộc về HVN.
                <br />
                - Nếu phát hiện có dấu hiệu gian lận, sử dụng công cụ, phần mềm hỗ
                trợ… tài khoản của người chơi sẽ bị khóa đến hết thời gian diễn ra chương
                trình, mọi quà tặng sẽ bị thu hồi.
                <br />
                - Bằng việc cung cấp thông tin và chọn TIẾP TỤC, tôi đồng ý cho HVN và
                bên cung cấp dịch vụ được HVN ủy quyền và được quản lý các thông tin cá
                nhân do tôi cung cấp vào mục đích thống kê, kiểm tra việc thực hiện chương
                trình. Mọi thắc mắc về chương trình tôi sẽ liên hệ hotline
                {' '}
                <b>1900 1845.</b>
                .
              </Typography.Text>
            </div>
            <div className="p-homeConditon_content_button">
              <Button
                primaryColor={COLORS.green}
                hoveredPrimaryColor={COLORS.greenHover}
                variant="primary"
                extendClasses="p-login_submit"
                onClick={() => setIsTerms(false)}
              >
                <Typography.Text
                  fontweight="500"
                  extendClasses="fs-16x24"
                >
                  TIẾP TỤC
                </Typography.Text>
              </Button>
            </div>
          </div>
        )}
      </CustomModal>
      <CustomModal
        isOpen={isOpen}
      >
        <div className="p-home_qrModal">
          <div className="p-home_qrModal_header">
            <Typography.Heading
              textStyle="center"
              extendClasses="fs-16x24 color-greenRyb"
              fontweight="700"
            >
              THÔNG BÁO
            </Typography.Heading>
          </div>
          <div className="p-home_qrModal_description">
            <Typography.Text
              extendClasses="fs-12x16"
            >
              Chào mừng bạn đến với xứ sở
              {' '}
              <b className="color-greenRyb">TẾT CHILL</b>
              {' '}
              – nơi tái hiện những
              phong tục mang đậm đặc trưng của mùa Tết Việt Nam.
              Bên cạnh những hoạt động trải nghiệm đầy thú
              vị, bạn còn có cơ hội nhận về hàng ngàn phần quà hấp dẫn với tỉ lệ trúng
              thưởng lên đến
              {' '}
              <b className="color-greenRyb">100%</b>
              . Khám phá ngay 5 khu vực trải nghiệm của
              {' '}
              <b className="color-greenRyb">TẾT CHILL</b>
              {' '}
              nhé!
              <br />
              1. Khu trải nghiệm sản phẩm
              <br />
              2. Bùa chill giã tay
              <br />
              3. Tarot chill
              <br />
              4. Thầy đồ Tết chill
              <br />
              5. Hái lộc
              <br />
              {' '}
              <br />
              <b>Thời gian hoạt động</b>
              : từ ngày 13/01/2024 đến
              ngày 09/02/2024 (có các khung giờ cụ thể cho từng khu vực).
              <br />
              <br />
              Bạn hãy chọn một điểm đến bất kỳ và bắt đầu lưu giữ hành
              trình tuyệt vời của mình tại
              {' '}
              <b className="color-greenRyb">TẾT CHILL</b>
              {' '}
              nhé!
            </Typography.Text>
          </div>
          <Button
            primaryColor={COLORS.green}
            hoveredPrimaryColor={COLORS.green}
            variant="primary"
            extendClasses="p-home_button"
            onClick={() => navigate(`${ROUTES_PATH.HOME}`)}
          >
            <Typography.Text
              fontweight="500"
              extendClasses="fs-16x24"
            >
              Bắt đầu
            </Typography.Text>
          </Button>
          <Button
            primaryColor={COLORS.tangerine}
            hoveredPrimaryColor={COLORS.tangerine}
            variant="primary"
            extendClasses="p-home_button p-home_button_closeModal"
            onClick={() => setIsOpen(false)}
          >
            <Typography.Text
              fontweight="500"
              extendClasses="fs-16x24"
            >
              Đóng
            </Typography.Text>
          </Button>
        </div>
      </CustomModal>
    </div>
  );
};

export default Login;
